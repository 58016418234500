import React, { useMemo } from 'react'
import { FormikContextType, FormikProvider } from 'formik'
import { CreateServiceContractType } from '../../../server/models/ServiceContract'
import { Input } from '../Inputs/Input'
import { Select } from '../Inputs/Select'
import { serviceIntervalTypes } from '../../../utils/serviceContracts/intervalTypes'

import './styles.css'

type Props = {
    formik: FormikContextType<CreateServiceContractType>
}

export const ScheduleIntervalInput: React.FC<Props> = ({ formik }) => {
    const intervalOptions = useMemo(() => {
        const capitalizeFirstLetter = (str: string) => {
            return str.charAt(0).toUpperCase() + str.slice(1)
        }

        return serviceIntervalTypes.map((interval) => {
            return {
                value: interval,
                label: capitalizeFirstLetter(interval).replaceAll('s', '(s)')
            }
        })
    }, [])

    return (
        <FormikProvider value={formik}>
            <label htmlFor="serviceIntervalAmount">
                Service Interval
                <div className="service-interval">
                    <span>Every</span>
                    <Input
                        type="number"
                        name="serviceIntervalAmount"
                        formik={formik}
                        label=""
                        min={1}
                    />
                    <Select
                        options={intervalOptions}
                        label=""
                        formik={formik}
                        name="serviceIntervalType"
                    />
                </div>
            </label>
        </FormikProvider>
    )
}

