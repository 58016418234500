import React from 'react'
import { Modal, ModalActions } from '../Modal'

type Props = {
    onCancel: () => void
    onDelete: () => void
    itemName: string
    show: boolean
}

export const DeleteConfirmationModal: React.FC<Props> = ({
    show,
    onCancel,
    onDelete,
    itemName
}) => {
    return (
        <Modal isOpen={show} onRequestClose={onCancel}>
            <h2>Confirm Delete</h2>
            <p>
                Are you sure you want to delete this {itemName}?
                <br/>Once deleted, it cannot be undone.
            </p>
            <ModalActions
                onSubmit={onDelete}
                onCancel={onCancel}
                submitText="Delete"
            />
        </Modal>
    )
}

