import { EquipmentType } from '../../server/models/Equipment'

export const emptyNewEquipment: EquipmentType = {
    identifier: '',
    type: '',
    size: '',
    manufacturer: '',
    model: '',
    serial: '',
    installDate: undefined
}
