import React from 'react'
import { toast } from 'react-toastify'
import { MessageType } from '../../../../server/models/Messages'
import {
    deleteMessage
} from '../../../requests/messages'
import { handleError } from '../../../errors/handleError'
import './styles.css'

const formatDate = (inDate: Date): string => {
    return new Date(inDate).toLocaleDateString()
}

type Props = {
    messages: MessageType[]
    onMessageRemove: (messageToRemove: MessageType) => void
}

const OneMessage: React.FC<{
    message: MessageType,
    onMessageRemove: (messageToRemove: MessageType) => void
}> = ({ message: msg, onMessageRemove }) => {

    if (msg.recurrenceIntervalDays) {
        return (
            <li>
                <p>To: {msg.emailTo ?? msg.textTo}</p>
                <p>Sent By: {msg.sentBy}</p>
                { msg.sendAt && (<p>Start Date: {formatDate(msg.sendAt)}</p>) }
                { msg.lastSent && (<p>Last Sent: {formatDate(msg.lastSent)}</p>) }
                <p>Sends every {msg.recurrenceIntervalDays} days</p>
                <br />
                <p>"{msg.message}"</p>
                { !msg.sent && <button type="button" onClick={() => onMessageRemove(msg)}>Remove Scheduled Message</button>}
            </li>
        )
    }

    return (
        <li>
            <p>To: {msg.emailTo ?? msg.textTo}</p>
            <p>Sent By: {msg.sentBy}</p>
            { msg.sendAt && (<p>Scheduled To Send: {formatDate(msg.sendAt)}</p>) }
            { msg.sent && (<p>Sent: {formatDate(msg.sendAt ?? msg.createdAt)}</p>) }
            <br />
            <p>"{msg.message}"</p>
            { !msg.sent && <button type="button" onClick={() => onMessageRemove(msg)}>Remove Scheduled Message</button>}
        </li>
    )
}

export const ListMessages: React.FC<Props> = ({
    messages,
    onMessageRemove
}) => {
    const handleRemoveScheduledMessage = async (messageToDelete: MessageType) => {
        try {
            await deleteMessage(messageToDelete._id)
            onMessageRemove(messageToDelete)
            toast.success('Message removed.')
        } catch (err) {
            handleError(err, 'Something went wrong removing this scheduled message. Please try again later.')
        }
    }

    return (
        <ul className="existing-messages">
            { messages.map((msg) =>  (
                <OneMessage
                    key={msg._id}
                    message={msg}
                    onMessageRemove={handleRemoveScheduledMessage}
                />
            ))}
        </ul>
    )
}
