import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Card } from '../../../components/Page'
import { getProjects } from '../../../requests/projects'
import { ProjectType } from '../../../../server/models/Project'
import { handleError } from '../../../errors/handleError'
import { getLatestStatus } from '../../../../utils/statusEvents/getLatestStatus'

export const ProjectsCard: React.FC = () => {
    const [projects, setProjects] = useState<ProjectType[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const res = await getProjects({ limit: 5, open: true })
                setProjects(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong loading your projects. Please try again later.')
            }

            setLoading(false)
        }

        fetchProjects()
    }, [])

    if (loading) {
        return (
            <Card title="Project Updates">
                <table>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                        </tr>
                        <tr>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                        </tr>
                        <tr>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        )
    }
    if (!projects.length) {
        return (
            <Card title="Project Updates">
                No open projects to show.
            </Card>
        )
    }

    return (
        <Card title="Project Updates">
            <table>
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        projects.map((project) => (
                            <tr key={project._id}>
                                <td>{project.customer.name}</td>
                                <td>{getLatestStatus(project.statusTimeline, project.createdAt).status}</td>
                                <td>{ new Date(project.updatedAt).toLocaleString()}</td>
                                <td>
                                    <Link to={`/app/projects/${project._id}`}>
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </Card>
    )
}

