import React from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { createWorkOrder } from '../../../requests/workOrders'
import { handleError } from '../../../errors/handleError'
import { createWorkOrderValidation } from '../../../../server/validation/workorder'
import { emptyNewWorkOrder } from '../../../../utils/workOrders/emptyNewWorkOrder'
import { WorkOrderForm } from './Form'

export const AddWorkOrder: React.FC = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: emptyNewWorkOrder,

        validationSchema: createWorkOrderValidation,

        onSubmit: async (values) => {
            try {
                await createWorkOrder(values)

                toast.success('Work Order successfully added.')

                navigate('/app/work-orders')
            } catch (err) {
                handleError(err, 'Something went wrong creating this work order. Please try again later.')
            }
        }
    })

    return (
        <>
            <h1>New Work Order</h1>
            <WorkOrderForm formik={formik} submitText="Create Work Order" hideMessages />
        </>
    )
}

