import React from 'react'
import { Card } from '../../../components/Page'

export const WarrantiesCard: React.FC = () => {
    return (
        <Card title="Upcoming Warranty Expirations">
            No upcoming warranty expirations to show.
        </Card>
    )
}

