import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page, Section, SectionActions } from '../../../components/Page'
import { ProjectType } from '../../../../server/models/Project'
import { getProjects, exportProjects } from '../../../requests/projects'
import { handleError } from '../../../errors/handleError'
import { ViewContactsModal } from '../../../components/Contacts/ViewContactsModal'
import { DeleteProjectModal } from './Delete'
import { ProjectsTable } from './Table'
import { ExportSpreadSheetButton } from '../../../components/ExportSpreadSheetButton'
import { SendMessageModal } from '../MessageCenter/SendMessageModal'

export const Projects: React.FC = () => {
    const [projects, setProjects] = useState<ProjectType[]>([])
    const [loading, setLoading] = useState(true)
    const [projectToView, setProjectToView] = useState<ProjectType>()
    const [projectToDelete, setProjectToDelete] = useState('')
    const [projectToSendMessagesFor, setProjectToSendMessagesFor] = useState<ProjectType>()

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const res = await getProjects()
                setProjects(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong loading the projects. Please try again later.')
            }

            setLoading(false)
        }

        fetchProjects()
    }, [])

    const handleProjectDeleted = (deletedProjectId: string) => {
        setProjects(
            projects.filter((project) => {
                return project._id !== deletedProjectId
            })
        )
    }

    return (
        <>
            <DeleteProjectModal
                show={!!projectToDelete}
                onClose={() => setProjectToDelete('')}
                onDeleted={handleProjectDeleted}
                projectId={projectToDelete}
            />
            <h1>Projects</h1>
            <ViewContactsModal
                show={!!projectToView}
                onClose={() => setProjectToView(null)}
                contacts={projectToView?.contacts ?? []}
                title={
                    projectToView
                        ? `${projectToView.customer.name} ${projectToView.classification} Project Contacts`
                        : 'Contacts'
                }
            />
            <SendMessageModal
                isOpen={!!projectToSendMessagesFor}
                onClose={() => setProjectToSendMessagesFor(null)}
                project={projectToSendMessagesFor}
            />
            <Page>
                <Section title="Projects List">
                    <SectionActions>
                        <ExportSpreadSheetButton
                            filePrefix='Projects-List'
                            requestFunction={exportProjects}
                        />
                        <Link to="/app/projects/new">
                            <button className="primary">
                                + New Project
                            </button>
                        </Link>
                    </SectionActions>
                    <ProjectsTable
                        projects={projects}
                        loading={loading}
                        onViewContacts={setProjectToView}
                        onDeleteProject={(project) => setProjectToDelete(project._id)}
                        onSendMessages={setProjectToSendMessagesFor}
                    />
                </Section>
            </Page>
        </>
    )
}

