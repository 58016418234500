import React from 'react'
import { toast } from 'react-toastify'
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal'
import { deleteProject } from '../../../requests/projects'
import { handleError } from '../../../errors/handleError'

type Props = {
    show: boolean
    onClose: () => void
    onDeleted: (projectId: string) => void
    projectId: string
}

export const DeleteProjectModal: React.FC<Props> = ({
    show,
    onClose,
    onDeleted,
    projectId
}) => {
    const onDelete = async () => {
        try {
            await deleteProject(projectId)
            onDeleted(projectId)
            toast.success('Project deleted.')
        } catch (err) {
            handleError(err, 'Something went wrong deleting this project. Please try again later.')
        }

        onClose()
    }

    return (
        <DeleteConfirmationModal
            show={show}
            onCancel={onClose}
            onDelete={onDelete}
            itemName='Project'
        />
    )
}

