import * as yup from 'yup'

export const createEquipmentValidation = yup.object({
    identifier: yup.string().required('An identifier number for this equipment is required.'),
    type: yup.string().required('Type of equiment is required.'),
    size: yup.string().required('Size of equipment is required.'),
    manufacturer: yup.string().required('Manufacturer of equipment is required.'),
    model: yup.string().required('Model of equipment is required.'),
    serial: yup.string().required('Serial number for this equipment is required.'),
    installDate: yup.date().required('Date of installation is required.'),
})
