import { AddressType } from '../../server/models/Address'

export const formatAddress = (inAddress:AddressType): string => {
    if (!inAddress) {
        return 'No Address'
    }
    let address = inAddress.name ? `${inAddress.name} - ` : ''
    address += inAddress.street

    if (inAddress.street2) {
        address += ` ${inAddress.street2}`
    }
    address += `, ${inAddress.city}, ${inAddress.state} ${inAddress.zip}`

    return address.trim()
}
