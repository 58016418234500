import React, { useEffect, useState } from 'react'
import { getCustomers } from '../../../requests/customer'
import { CustomerType } from '../../../../server/models/Customer'
import { handleError } from '../../../errors/handleError'
import './styles.css'

type Props = {
    onCustomersFound: (customers: CustomerType[]) => void
}

export const SearchCustomersInput: React.FC<Props> = ({
    onCustomersFound
}) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedTerm(searchTerm)
        }, 1000)

        return () => {
            clearTimeout(handler)
        }
    }, [searchTerm])

    useEffect(() => {
        const fetchCustomers = async () => {
            setLoading(true)
            try {
                const res = await getCustomers({
                    q: debouncedTerm
                })

                const sortedCustomers = res.data.sort((a: CustomerType, b: CustomerType) => a.name.localeCompare(b.name))
                onCustomersFound(sortedCustomers)
            } catch (err) {
                handleError(err, 'Something went wrong searching your customers. Please come back later.')
            }
            setLoading(false)
        }

        fetchCustomers()
    }, [debouncedTerm])

    return (
        <label htmlFor="search" className="customer-search">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path></svg>
            <input
                type="search"
                name="search"
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search customers..."
                disabled={loading}
                prefix=''
            />
        </label>
    )
}

