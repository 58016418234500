import React, { useCallback, useEffect, useState } from 'react'
import { FormikContextType } from 'formik'
import { Address } from '../Address'
import { AddressType } from '../../../server/models/Address'
import { getCustomerServiceAddresses } from '../../requests/customer'
import { formatAddress } from '../../../utils/address/formatAddress'
import { emptyAddress } from '../../../utils/address/emptyAddress'

type Props = {
    formik: FormikContextType<any>
    disabled: boolean
}

export const ServiceAddress: React.FC<Props> = ({
    formik,
    disabled
}) => {
    const [
        customerServiceAddresses,
        setCustomerServiceAddresses
    ] = useState<AddressType[]>([])

    const [
        selectedServiceAddress,
        setSelectedServiceAddress
    ] = useState<string>('')

    const getServiceAddresses = useCallback(async () => {
        const customerId = formik.values.customer

        if (!customerId) {
            setCustomerServiceAddresses([])
            return
        }
        setSelectedServiceAddress('new')

        try {
            const res = await getCustomerServiceAddresses(customerId)
            setCustomerServiceAddresses(res.data)
        } catch (err) {
            setCustomerServiceAddresses([])
        }
    }, [formik.values.customer])

    useEffect(() => {
        getServiceAddresses()
    }, [getServiceAddresses])

    useEffect(() => {
        if (!disabled) {
            setSelectedServiceAddress('new')
        }
    }, [disabled])

    useEffect(() => {
        if (selectedServiceAddress !== 'new') {
            const address = customerServiceAddresses.find((address) => {
                return formatAddress(address) === selectedServiceAddress
            })

            if (address) {
                formik.setFieldValue('serviceAddress', {
                    ...emptyAddress,
                    ...address
                })
            }
        }
    }, [selectedServiceAddress, customerServiceAddresses])

    useEffect(() => {
        if (formik.values.serviceAddress && selectedServiceAddress !== 'new') {
            if (formatAddress(formik.values.serviceAddress) !== selectedServiceAddress) {
                setSelectedServiceAddress('new')
            }
        }
    }, [formik.values.serviceAddress])

    return (
        <>
            { !disabled && (<label htmlFor="search">
                Select Service Address
                <select
                    name="selectedServiceAddress"
                    onChange={(e) => setSelectedServiceAddress(e.target.value)}
                    value={selectedServiceAddress}
                >
                    <option value="new" disabled>Add New Address</option>
                    {
                        customerServiceAddresses.map((address) => (
                            <option key={formatAddress(address)} value={formatAddress(address)}>
                                {formatAddress(address)}
                            </option>
                        ))
                    }
                </select>
            </label>)}
            <Address
                name="serviceAddress"
                label={disabled ? 'Service Address' : ''}
                formik={formik}
                disabled={disabled}
            />
        </>
    )
}

