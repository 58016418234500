import { AxiosResponse } from 'axios'
import { $axios } from './axios'
import { CreateMessageType, MessageType } from '../../server/models/Messages'
import { ContactType } from '../../server/models/Contact'

export const createMessage = (body: CreateMessageType): Promise<AxiosResponse<MessageType>> => {
    return $axios.post('/messages', body)
}

export const getMessages = (params: {
    workOrder?: string
    project?: string
    serviceContract?: string
}): Promise<AxiosResponse<MessageType[]>> => {
    return $axios.get('/messages', { params })
}

export const getMessageContactList = (params: {
    projectId?: string
    serviceContractId?: string
    workOrderId?: string
} = {}): Promise<AxiosResponse<ContactType[]>> => {
    return $axios.get('/messages/available-contacts', { params })
}

export const deleteMessage = (messageId: string): Promise<AxiosResponse<void>> => {
    return $axios.delete(`/messages/${messageId}`)
}
