import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'

import { emptyNewProject } from '../../../../utils/projects/emptyNewProject'
import { createProject } from '../../../requests/projects'
import { createProjectValidation } from '../../../../server/validation/projects'
import { handleError } from '../../../errors/handleError'
import { ProjectForm } from './Form'

export const AddProject: React.FC = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: emptyNewProject,

        validationSchema: createProjectValidation,

        onSubmit: async (values) => {
            try {
                await createProject(values)

                toast.success('Project successfully added.')

                navigate('/app/projects')
            } catch (err) {
                handleError(err, 'Something went wrong creating this Project. Please try again later.')
            }
        }
    })

    return (
        <>
            <h1>New Project</h1>
            <ProjectForm formik={formik} submitButtonText="Create Project" hideMessages />
        </>
    )
}

