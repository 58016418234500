import React, { useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Page, Section, SectionActions } from '../../../components/Page'
import { SendMessageModal } from './SendMessageModal'
import { getMessages } from '../../../requests/messages'
import { MessageType } from '../../../../server/models/Messages'
import { handleError } from '../../../errors/handleError'

export const MessageCenter: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [allMessages, setAllMessages] = useState<MessageType[]>([])
    const [sendMessageModalShowing, showSendMessageModal] = useState(false)

    const onMessageSent = (newMessages: MessageType[]) => {
        setAllMessages([
            ...newMessages,
            ...allMessages
        ])
    }

    useEffect(() => {
        const fetchMessages = async (): Promise<void> => {
            try {
                const res = await getMessages({})
                setAllMessages(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong fetching your messages. Please try again later.')
            }

            setLoading(false)
        }

        fetchMessages()
    }, [])

    const sentMessages = allMessages.filter((msg) => msg.sent)
    const scheduledMessages = allMessages.filter((msg) => msg.sendAt && !msg.sent)

    return (
        <>
            <SendMessageModal
                isOpen={sendMessageModalShowing}
                onClose={() => showSendMessageModal(false)}
                onMessagesSent={onMessageSent}
            />
            <h1>Message Center</h1>
            <Page>
                <Section title="Messages">
                    <SectionActions>
                        <button className="primary" onClick={() => showSendMessageModal(true)}>
                            + New Message
                        </button>
                    </SectionActions>
                    <Tabs>
                        <TabList>
                            <Tab>Sent ({sentMessages.length})</Tab>
                            <Tab>Scheduled Messages ({scheduledMessages.length})</Tab>
                        </TabList>
                        <TabPanel>
                            {
                                loading && (
                                    <>
                                        <Skeleton height="100px" />
                                        <br/>
                                        <Skeleton height="18px" />
                                        <hr/>
                                        <Skeleton height="100px" />
                                        <br/>
                                        <Skeleton height="18px" />
                                        <hr/>
                                        <Skeleton height="100px" />
                                        <br/>
                                        <Skeleton height="18px" />
                                        <hr/>
                                        <Skeleton height="100px" />
                                        <br/>
                                        <Skeleton height="18px" />
                                        <hr/>
                                        <Skeleton height="100px" />
                                        <br/>
                                        <Skeleton height="18px" />
                                        <hr/>
                                        <Skeleton height="100px" />
                                        <br/>
                                        <Skeleton height="18px" />
                                        <hr/>
                                    </>
                                )
                            }
                            {
                                sentMessages.map((msg) => (
                                    <div>
                                        <h3>To: {msg.textTo ?? msg.emailTo}</h3>
                                        <h4>From: {msg.sentBy}</h4>
                                        <p>
                                            {msg.message}
                                        </p>
                                        <h6>{new Date(msg.createdAt).toLocaleString()}</h6>
                                        <hr/>
                                    </div>
                                ))
                            }
                        </TabPanel>
                        <TabPanel>
                            {
                                scheduledMessages.map((msg) => (
                                    <div>
                                        <h3>Scheduled To Send: {new Date(msg.sendAt).toLocaleDateString()}</h3>
                                        <h3>To: {msg.textTo ?? msg.emailTo}</h3>
                                        <h4>From: {msg.sentBy}</h4>
                                        <p>
                                            {msg.message}
                                        </p>
                                        <h6>{new Date(msg.createdAt).toLocaleString()}</h6>
                                        <hr/>
                                    </div>
                                ))
                            }
                        </TabPanel>
                    </Tabs>
                </Section>
            </Page>
        </>
    )
}
