import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getServiceContract } from '../../../requests/serviceContracts'
import { ServiceContractType } from '../../../../server/models/ServiceContract'
import { handleError } from '../../../errors/handleError'
import { formatScheduleInterval } from '../../../../utils/serviceContracts/formatScheduleInterval'
import { PrintTable } from '../../../components/PrintContainer'
import { Equipment } from '../../../components/PrintContainer/Equipment'
import { Status } from '../../../components/PrintContainer/Status'
import { Contacts } from '../../../components/PrintContainer/Contacts'
import { Warranties } from '../../../components/PrintContainer/Warranties'
import { Notes } from '../../../components/PrintContainer/Notes'
import { CustomerInfo } from '../../../components/PrintContainer/Customer'
import { formatAddress } from '../../../../utils/address/formatAddress'

export const PrintServiceContract: React.FC = () => {
    const { serviceContractId } = useParams()

    const [serviceContract, setServiceContract] = useState<ServiceContractType>()

    useEffect(() => {
        const fetchThisServiceContract = async (): Promise<void> => {
            try {
                const res = await getServiceContract(serviceContractId)
                setServiceContract(res.data)
            } catch (err) {
                handleError(
                    err,
                    'Something went wrong loading this service contract. Please try again later.'
                )
            }
        }

        fetchThisServiceContract()
    }, [serviceContractId])

    if (!serviceContract) {
        return null
    }

    return (
        <>
            <CustomerInfo customer={serviceContract.customer} customerPo={serviceContract.customerPo} />
            <PrintTable>
                <thead>
                    <tr>
                        <th colSpan={2}>Service Contract Information</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Created At
                        </td>
                        <td>
                            {new Date(serviceContract.createdAt).toLocaleDateString()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Last Updated At
                        </td>
                        <td>
                            {new Date(serviceContract.updatedAt).toLocaleDateString()}
                        </td>
                    </tr>
                    { !!serviceContract.proposalNumber && (
                        <tr>
                            <td>
                                Proposal Number
                            </td>
                            <td>
                                {serviceContract.proposalNumber}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            Service Interval
                        </td>
                        <td>
                            {formatScheduleInterval(serviceContract)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Billing Address
                        </td>
                        <td>
                            {formatAddress(serviceContract.billingAddress)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Service Address
                        </td>
                        <td>
                            {formatAddress(serviceContract.serviceAddress)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Classification
                        </td>
                        <td>
                            {serviceContract.classification}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Description
                        </td>
                        <td>
                            {serviceContract.description}
                        </td>
                    </tr>
                </tbody>
            </PrintTable>
            <Contacts contacts={serviceContract.contacts} />
            <Status statusTimeline={serviceContract.statusTimeline} />
            <Equipment equipment={serviceContract.equipment} />
            <Warranties warranties={serviceContract.warranties} />
            <Notes />
        </>
    )
}

