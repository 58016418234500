type NestedObject = { [key: string]: any };

export function getValueByPath(obj: NestedObject, path: string): any {
    const keys = path.split('.');
    let current = obj;

    for (const key of keys) {
        if (current[key] === undefined) {
            return undefined;
        }
        current = current[key];
    }

    return current;
}
