import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { WorkOrderType } from '../../../server/models/WorkOrder'
import { ServiceContractType } from '../../../server/models/ServiceContract'

// components
import { Modal, ModalActions } from '../Modal'
import { TextArea } from '../Inputs/TextArea'

// requests
import { createNotification } from '../../requests/notifications'

// utils
import { handleError } from '../../errors/handleError'

type Props = {
    workOrder: WorkOrderType
    serviceContract: ServiceContractType
    isOpen: boolean
    onClose: () => void
}

export const SendNotificationsModal: React.FC<Props> = ({
    isOpen,
    workOrder,
    serviceContract,
    onClose
}) => {
    const formik = useFormik({
        initialValues: {
            extraMessage: ''
        },
        onSubmit: async (values) => {
            if (
                workOrder?.assignedTechnicians?.length &&
                serviceContract?.assignedTechnicians.length
            ) {
                return toast.error('No assigned technicians to notify.')
            }

            try {
                await createNotification({
                    extraMessage: values.extraMessage,
                    workOrderId: workOrder?._id,
                    serviceContractId: serviceContract?._id
                })

                toast.success('Notifications sent.')
            } catch (err) {
                handleError(err, 'Something went wrong with this notification. Please try again later.')
            }

            onClose()
        }
    })

    const onRequestClose = () => {
        if (formik.isSubmitting) {
            return
        }

        onClose()
    }

    const previewMessage = useMemo((): string => {
        let url = window.location.origin

        if (workOrder) {
            url += '/print/work-orders/' + workOrder._id
        }

        if (serviceContract) {
            url += '/print/service-contracts/' + serviceContract._id
        }

        return `
You have been assigned a new ${workOrder ? 'Work Order' : 'Service Contract'}.\n\n${formik.values.extraMessage ?? ''}${formik.values.extraMessage ? '\n' : ''}
Review here: ${url}

Reply STOP to stop receiving these notifications.`.trim()
    }, [workOrder, serviceContract, formik.values.extraMessage])

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <h2>Notify Technicians</h2>
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="preview">
                    Notification Message Preview
                    <textarea
                        name="preview"
                        value={previewMessage}
                        readOnly
                        rows={3}
                    />
                </label>
                <TextArea
                    formik={formik}
                    label="Additional Message (Optional)"
                    name="extraMessage"
                    required={false}
                    rows={3}
                />
                <ModalActions
                    submitText="Notify"
                    onCancel={onRequestClose}
                    onSubmit={formik.handleSubmit}
                    loading={formik.isSubmitting}
                />
            </form>
        </Modal>
    )
}

