import React from 'react'
import { toast } from 'react-toastify'
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal'
import { deleteWorkOrder } from '../../../requests/workOrders'
import { handleError } from '../../../errors/handleError'

type Props = {
    show: boolean
    onClose: () => void
    onDeleted: (workOrderId: string) => void
    workOrderId: string
}

export const DeleteWorkOrderModal: React.FC<Props> = ({
    show,
    onClose,
    onDeleted,
    workOrderId
}) => {
    const onDelete = async () => {
        try {
            await deleteWorkOrder(workOrderId)
            onDeleted(workOrderId)
            toast.success('Work Order deleted.')
        } catch (err) {
            handleError(err, 'Something went wrong deleting this work order. Please try again later.')
        }

        onClose()
    }

    return (
        <DeleteConfirmationModal
            show={show}
            onCancel={onClose}
            onDelete={onDelete}
            itemName='Work Order'
        />
    )
}

