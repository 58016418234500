import React from 'react'
import { FormikContextType, FormikProvider, FieldArray } from 'formik'
import { PhoneNumberType } from '../../../server/models/PhoneNumber'
import { ContactType } from '../../../server/models/Contact'
import { Input } from '../Inputs/Input'
import './styles.css'

type HasContacts = {
  contacts: ContactType[];
} & Record<string, any>;

type Props = {
    formik: FormikContextType<HasContacts>
    contactIndex: number
}

export const PhoneNumbers: React.FC<Props> = ({
    formik,
    contactIndex
}) => {
    return (
        <FormikProvider value={formik}>
            <FieldArray name={`contacts.${contactIndex}.phoneNumbers`}>
                {({ push, remove }) => (
                    <div className="phone-number-group">
                        <label htmlFor="phoneNumbers">
                            Phone Numbers
                        </label>
                        {formik.values.contacts[contactIndex].phoneNumbers?.map((phoneNumber: PhoneNumberType, index: number) => (
                            <div key={index} className="phone-number">
                                <Input
                                    type="text"
                                    label="Phone Type"
                                    name={`contacts.${contactIndex}.phoneNumbers.${index}.name`}
                                    formik={formik}
                                    value={phoneNumber.name}
                                    placeholder="Enter type of phone number"
                                    required
                                />
                                <Input
                                    type="text"
                                    label="Phone Number"
                                    name={`contacts.${contactIndex}.phoneNumbers.${index}.phone`}
                                    formik={formik}
                                    value={phoneNumber.phone}
                                    placeholder="Enter phone number"
                                    required
                                />
                                <button type="button" className="secondary" onClick={() => remove(index)}>Remove</button>
                            </div>
                        ))}
                        <button type="button" className="primary" onClick={() => push({ name: '', phone: '' })}>Add Phone</button>
                    </div>
                )}
            </FieldArray>
        </FormikProvider>
    )
}

