import React from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { createServiceContract } from '../../../requests/serviceContracts'
import { handleError } from '../../../errors/handleError'
import { createServiceContractValidation } from '../../../../server/validation/serviceContracts'
import { ServiceContractForm } from './Form'
import { emptyNewServiceContract } from '../../../../utils/serviceContracts/emptyNewServiceContract'

export const AddServiceContract: React.FC = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: emptyNewServiceContract,

        validationSchema: createServiceContractValidation,

        onSubmit: async (values) => {
            try {
                await createServiceContract(values)

                toast.success('Service Contract successfully added.')

                navigate('/app/service-contracts')
            } catch (err) {
                handleError(err, 'Something went wrong creating this service contract. Please try again later.')
            }
        }
    })

    return (
        <>
            <h1>New Service Contract</h1>
            <ServiceContractForm formik={formik} submitButtonText="Create Service Contract" hideMessages />
        </>
    )
}

