import React from 'react'
import { FormikContextType, FormikProvider, FieldArray } from 'formik'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { WarrantyType } from '../../../server/models/Warranty'
import { warrantyTypes } from '../../../utils/warranties/warrantyTypes'
import { emptyNewWarranty } from '../../../utils/warranties/emptyNewWarranty'
import { FormSection } from '../Page'
import { Input } from '../Inputs/Input'
import { DateInput } from '../Inputs/Date'
import { Select } from '../Inputs/Select'
import { SelectEquipment } from '../Equipment/SelectEquipment'
import { Empty } from '../Empty'
import { SelectContactsToNotify } from '../Contacts/SelectContactsToNotify'

type Props = {
    formik: FormikContextType<any>
}

export const AddWarranties: React.FC<Props> = ({ formik }) => {
    const formatWarrantyTabTitle = (warranty: WarrantyType, index: number): string => {
        if (!warranty.company) {
            return `New Warranty #${index + 1}`
        }
        
        return warranty.company
    }

    return (
        <FormikProvider value={formik}>
            <FieldArray name="warranties">
                {({ push, remove }) => (
                    <Tabs>
                        <TabList>
                            {formik.values.warranties.map((warranty: WarrantyType, index: number) => (
                                <Tab key={index}>
                                    { formatWarrantyTabTitle(warranty, index) }
                                    <button title="Remove Warranty" type="button" onClick={() => remove(index)}>
                                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_194_977)"> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00006 9.41395L11.5354 12.9499C11.723 13.1375 11.9774 13.2429 12.2427 13.2429C12.508 13.2429 12.7625 13.1375 12.9501 12.9499C13.1377 12.7624 13.243 12.5079 13.243 12.2426C13.243 11.9773 13.1377 11.7229 12.9501 11.5353L9.41339 7.99995L12.9494 4.46462C13.0422 4.37173 13.1159 4.26146 13.1661 4.14012C13.2163 4.01877 13.2422 3.88872 13.2421 3.75738C13.2421 3.62605 13.2162 3.49601 13.1659 3.37468C13.1156 3.25336 13.0419 3.14313 12.9491 3.05028C12.8562 2.95744 12.7459 2.8838 12.6246 2.83357C12.5032 2.78334 12.3732 2.7575 12.2418 2.75753C12.1105 2.75756 11.9805 2.78346 11.8591 2.83375C11.7378 2.88404 11.6276 2.95773 11.5347 3.05062L8.00006 6.58595L4.46473 3.05062C4.37253 2.95507 4.26222 2.87883 4.14024 2.82637C4.01826 2.7739 3.88705 2.74625 3.75427 2.74504C3.6215 2.74382 3.48981 2.76906 3.36689 2.81928C3.24397 2.86951 3.13228 2.94371 3.03834 3.03756C2.94441 3.1314 2.8701 3.24302 2.81976 3.36589C2.76942 3.48876 2.74406 3.62043 2.74515 3.75321C2.74624 3.88599 2.77377 4.01722 2.82612 4.13925C2.87847 4.26128 2.9546 4.37166 3.05006 4.46395L6.58673 7.99995L3.05073 11.5353C2.86313 11.7229 2.75774 11.9773 2.75774 12.2426C2.75774 12.5079 2.86313 12.7624 3.05073 12.9499C3.23832 13.1375 3.49276 13.2429 3.75806 13.2429C4.02336 13.2429 4.2778 13.1375 4.46539 12.9499L8.00006 9.41328V9.41395Z" fill="black"/> </g> <defs> <clipPath id="clip0_194_977"> <rect width="16" height="16" fill="white"/> </clipPath> </defs> </svg>
                                    </button>
                                </Tab>
                            ))}
                            <Tab onClick={() => push(emptyNewWarranty)}>
                                + Add New Warranty
                            </Tab>
                        </TabList>
                        {
                            formik.values.warranties.length === 0 && (
                                <TabPanel>
                                    <Empty>No Warranties</Empty>
                                </TabPanel>
                            )
                        }
                        {formik.values.warranties.map((warranty: WarrantyType, index: number) => (
                            <TabPanel key={index}>
                                <FormSection title="">
                                    <SelectEquipment
                                        label="Select Equipment"
                                        equipment={formik.values.equipment}
                                        formik={formik}
                                        value={warranty.equipmentSerial}
                                        name={`warranties.${index}.equipmentSerial`}
                                    />
                                    <Input
                                        label="Warranty Company"
                                        type="text"
                                        name={`warranties.${index}.company`}
                                        value={warranty.company}
                                        formik={formik}
                                        placeholder="Enter the guarantor of this warranty" 
                                    />

                                    <Select
                                        label="Type of Warranty"
                                        name={`warranties.${index}.type`}
                                        formik={formik}
                                        value={warranty.type}
                                        options={warrantyTypes.map((type) => ({ value: type, label: `${type} Warranty`}))}
                                        placeholder="Select type of warranty..." 
                                    />

                                    <Input
                                        label="Confirmation Number"
                                        type="text"
                                        name={`warranties.${index}.confirmationNumber`}
                                        formik={formik}
                                        value={warranty.confirmationNumber}
                                    />

                                    <DateInput
                                        label="Expiration"
                                        name={`warranties.${index}.expirationDate`}
                                        formik={formik}
                                        value={warranty.expirationDate}
                                    />

                                    <SelectContactsToNotify
                                        contacts={formik.values.contacts}
                                        toNotify={warranty.notify}
                                        onNotifiesChanged={
                                            (newNotify) => formik.setFieldValue(`warranties.${index}.notify`, newNotify)
                                        }
                                    />
                                </FormSection>
                            </TabPanel>
                        ))}
                    </Tabs>
                )}
            </FieldArray>
        </FormikProvider>
    )
}

