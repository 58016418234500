import React, { useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { getSession } from '../../requests/session'
import QRCode from 'react-qr-code'
import { Outlet } from 'react-router-dom'

import './styles.css'

export const PrintLayout: React.FC = () => {
    const [initializing, setInitializing] = useState(true)

    useEffect(() => {
        const checkAuth = async () => {
            try {
                await getSession()
                setInitializing(false)
            } catch (err) {
                if (isAxiosError(err) && err.response?.status === 400) {
                    // redirect to login page with param saving this page url
                    window.location.href = '/?redirect=' + window.location.pathname
                }
            }
        }

        checkAuth()
    }, [])

    return (
        <div className="print-container">
            <button className="primary" onClick={() => window.print()}>
                Print
            </button>
            <div className="print-header">
                <div className="logo">
                    <img src="/assets/logo.png" width={100} height={100} />
                </div>
                <div className="info">
                    <h3>Lowry Construction and Mechanical LLC</h3>
                    <p>33 Three Hunts Drive</p>
                    <p>Pembroke NC 28372</p>
                    <br/>
                    <p>Office: 910.522.1234</p>
                    <p>Web: www.LcmLowry.com</p>
                    <p>Email: qlowry@lcmlowry.com</p>
                </div>
                <div className="qr-code">
                    <QRCode value={window.location.href} size={200} />
                </div>
            </div>
            <div className="print-content">
                { !initializing && <Outlet /> }
            </div>
        </div>
    )
}

