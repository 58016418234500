import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { Modal } from '../../Modal'
import { Input } from '../../Inputs/Input'
import { ManufacturerType } from '../../../../server/models/Manufacturer'
import {
    updateManufacturer,
    deleteManufacturer
} from '../../../requests/manufacturers'
import { handleError } from '../../../errors/handleError'
import './styles.css'

const OneManufacturer: React.FC<{
    manufacturer: ManufacturerType,
    onSubmit: () => void
}> = ({ manufacturer, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            name: manufacturer.name
        },
        onSubmit: async (values) => {
            try { 
                await updateManufacturer(manufacturer._id, values)
                toast.success('Manufacturer updated.')
                onSubmit()
            } catch (err) {
                handleError(err, 'Something went wrong updating this manufacturer. Please try again later.')
            }
        }
    })

    const onDelete = async () => {
        try {
            await deleteManufacturer(manufacturer._id)
            toast.success('Manufacturer deleted.')
            onSubmit()
        } catch (err) {
            handleError(err, 'Something went wrong deleting this manufacturer. Please try again later.')
        }
    }

    return (
        <div key={manufacturer._id} className="one-manufacturer">
            <Input
                label=""
                name="name"
                formik={formik}
                type="text"
                required
            />

            <button
                type="button"
                className="secondary"
                onClick={onDelete}
            >
                Delete
            </button>

            <button
                type="button"
                className="primary"
                disabled={!formik.dirty || formik.isSubmitting}
                onClick={formik.submitForm}
            >
                Save
            </button>
        </div>
    )
}

type Props = {
    manufacturers: ManufacturerType[]
    onManufacturerUpdated: () => void
}

export const EditManufacturers: React.FC<Props> = ({
    manufacturers,
    onManufacturerUpdated
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const onClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose}>
                <h2>Edit Manufacturers</h2>
                {
                    manufacturers.length === 0 && <p>No manufacturers to show.</p>
                }
                {
                    manufacturers.map((manufacturer) => (
                        <OneManufacturer manufacturer={manufacturer} onSubmit={onManufacturerUpdated} />
                    ))
                }
                <div className="modal-actions">
                    <button className="secondary" type="button" onClick={onClose}>
                        Close
                    </button>
                </div>
            </Modal>

            {
                manufacturers.length > 0 && (
                    <button className="secondary edit-manufacturers" onClick={() => setIsOpen(true)}>
                        Edit Manufacturers
                    </button>
                )
            }
        </>
    )
}

