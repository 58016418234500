import { AddressType } from '../../server/models/Address'

export const emptyAddress: AddressType = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: ''
}
