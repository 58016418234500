import React from 'react'

import './styles.css'

type Props = {
    children: any
}

export const PrintTable: React.FC<Props> = ({ children }) => {
    return (
        <table className="print-table">
            {children}
        </table>
    )
}

