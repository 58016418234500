import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getProjects } from '../../../requests/projects'
import { ProjectType } from '../../../../server/models/Project'

type Props = {
    customerId: string
}

export const CustomerProjectsCell: React.FC<Props> = ({
    customerId
}) => {
    const [customerProjects, setCustomerProjects] = useState<ProjectType[]>([])
    const [failed, setFailed] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchProjectsCount = async (): Promise<void> => {
            try {
                const res = await getProjects({ customer: customerId })
                setCustomerProjects(res.data)
            } catch (err) {
                setFailed(true)
            }

            setLoading(false)
        }

        fetchProjectsCount()
    }, [customerId])

    if (loading) {
        return <Skeleton width="82px" />
    }

    if (failed) {
        return (
            <>Failed to fetch projects</>
        )
    }

    return (
        <>{customerProjects.length} Project{customerProjects.length === 1 ? '' : 's'}</>
    )
}

