import { StatusEventType } from '../../server/models/StatusEvent'

export const getLatestStatus = (statusTimeline: StatusEventType[], createdAt: Date): StatusEventType => {
    statusTimeline.sort((a, b) => {
        const dateA = new Date(a.statusObtainedAt);
        const dateB = new Date(b.statusObtainedAt);
        return dateA.getTime() - dateB.getTime();
    })

    if (!statusTimeline.length) {
        return {
            status: 'Created',
            statusObtainedAt: new Date(createdAt)
        }
    }

    const lastStatusEvent = statusTimeline[statusTimeline.length - 1]

    return lastStatusEvent
}
