import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import {
    getServiceContract,
    updateServiceContract
} from '../../../requests/serviceContracts'
import { handleError } from '../../../errors/handleError'
import { updateServiceContractValidation } from '../../../../server/validation/serviceContracts'
import { ServiceContractForm } from './Form'
import { emptyNewServiceContract } from '../../../../utils/serviceContracts/emptyNewServiceContract'
import { PageHeader } from '../../../components/Page'
import { useScrollToHashAfterLoading } from '../../../hooks/useScrollToHashAfterLoading'

export const EditServiceContract: React.FC = () => {
    const { serviceContractId } = useParams()

    const [loading, setLoading] = useState(true)
    useScrollToHashAfterLoading(loading)

    const formik = useFormik({
        initialValues: emptyNewServiceContract,

        validationSchema: updateServiceContractValidation,

        onSubmit: async (values) => {
            try {
                await updateServiceContract(serviceContractId, values)

                toast.success('Service Contract saved.')
            } catch (err) {
                handleError(err, 'Something went wrong creating this service contract. Please try again later.')
            }
        }
    })

    useEffect(() => {
        const fetchServiceContract = async () => {
            setLoading(true)

            try {
                const res = await getServiceContract(serviceContractId)

                formik.setValues({
                    ...res.data,
                    customer: res.data.customer._id
                })
            } catch (err) {
                handleError(err, 'Something went wrong loading this service contract. Please try again later.')
            }

            setLoading(false)
        }

        fetchServiceContract()
    }, [serviceContractId])

    return (
        <>
            <PageHeader title="Edit Service Contract">
                <a href={`/print/service-contracts/${serviceContractId}`} target="_blank" rel="noopener noreferrer">
                    <button className="primary">
                        Print
                    </button>
                </a>
            </PageHeader>
            <ServiceContractForm formik={formik} submitButtonText="Save Changes" />
        </>
    )
}

