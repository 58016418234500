import React from 'react'
import { PrintTable } from './index'
import { WarrantyType } from '../../../server/models/Warranty'

type Props = {
    warranties: WarrantyType[]
}

export const Warranties: React.FC<Props> = ({ warranties }) => {
    return (
            <PrintTable>
                <thead>
                    <tr>
                        <th colSpan={2}>Warranties</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        warranties.map((wty, index) => (
                            <>
                                <tr>
                                    <td>Company</td>
                                    <td>{wty.company}</td>
                                </tr>
                                <tr>
                                    <td>Type</td>
                                    <td>{wty.type}</td>
                                </tr>
                                <tr>
                                    <td>Confirmation Number</td>
                                    <td>{wty.confirmationNumber}</td>
                                </tr>
                                <tr>
                                    <td>Expiration</td>
                                    <td>{ new Date(wty.expirationDate).toLocaleDateString()}</td>
                                </tr>
                                <tr>
                                    <td>Equipment Serial Number</td>
                                    <td>{wty.equipmentSerial}</td>
                                </tr>
                                { warranties[index + 1] && (<tr>
                                    <td colSpan={2} />
                                </tr>)}
                            </>
                        ))
                    }
                </tbody>
            </PrintTable>
    )
}

