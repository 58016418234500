import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Dropdown } from '../../../components/Dropdown'
import { CustomerType } from '../../../../server/models/Customer'
import { Empty } from '../../../components/Empty'
import { Table } from '../../../components/Table'
import { ViewContactsButton } from '../../../components/ViewContactsButton'
import { CustomerProjectsCell } from './CustomerProjectsCell'
import { CustomerWorkOrdersCell } from './CustomerWorkOrdersCell'
import { formatAddress } from '../../../../utils/address/formatAddress'

const Thead = () => {
    return (
        <thead>
            <tr>
                <th>Owner</th>
                <th>Billing Address</th>
                <th>Contacts</th>
                <th>Projects</th>
                <th>Work Orders</th>
                <th></th>
            </tr>
        </thead>
    )
}

type Props = {
    customers: CustomerType[]
    loading: boolean
    onViewContacts: (customer: CustomerType) => void
    onDeleteCustomer: (customer: CustomerType) => void
}
export const CustomerTable: React.FC<Props> = ({
    customers,
    loading,
    onViewContacts,
    onDeleteCustomer
}) => {
    if (loading) {
        return (
            <Table>
                <Thead />
                <tbody>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    if (customers.length === 0) {
        return <Empty>No customers to show at the moment.</Empty>
    }

    return (
        <Table>
            <Thead />
            <tbody>
                {
                    customers.map((customer) => (
                        <tr key={customer._id}>
                            <td>{customer.name}</td>
                            <td>{formatAddress(customer.address)}</td>
                            <td>
                                <ViewContactsButton
                                    onClick={() => onViewContacts(customer)}
                                    contacts={customer.contacts}
                                />
                            </td>
                            <td><CustomerProjectsCell customerId={customer._id} /></td>
                            <td><CustomerWorkOrdersCell customerId={customer._id} /></td>
                            <td>
                                <Dropdown
                                    items={[
                                        {
                                            label: 'Edit Customer',
                                            href: `/app/customers/${customer._id}`
                                        },
                                        {
                                            label: 'View Contacts',
                                            onClick: () => onViewContacts(customer)
                                        }, {
                                            label: 'Delete Customer',
                                            onClick: () => onDeleteCustomer(customer)
                                        }
                                    ]}
                                />
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

