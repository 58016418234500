import React from 'react'
import { PrintTable } from './index'
import { CustomerType } from '../../../server/models/Customer'
import { formatAddress } from '../../../utils/address/formatAddress'

type Props = {
    customer: CustomerType
    customerPo: string
}

export const CustomerInfo: React.FC<Props> = ({ customer, customerPo }) => {
    return (
        <PrintTable>
            <thead>
                <tr>
                    <th colSpan={2}>Customer Info</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>{customer.name}</td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td>{formatAddress(customer.address)}</td>
                </tr>
                <tr>
                    <td>PO</td>
                    <td>{customerPo || 'No PO'}</td>
                </tr>
            </tbody>
        </PrintTable>
    )
}

