import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page, Section, SectionActions } from '../../../components/Page'
import { CustomerType } from '../../../../server/models/Customer'
import { getCustomers, exportCustomers } from '../../../requests/customer'
import { handleError } from '../../../errors/handleError'
import { DeleteCustomerModal } from './Delete'
import { CustomerTable } from './Table'
import { SearchCustomersInput } from '../../../components/Customers/SearchCustomersInput'
import { ViewContactsModal } from '../../../components/Contacts/ViewContactsModal'
import { ExportSpreadSheetButton } from '../../../components/ExportSpreadSheetButton'

export const Customers: React.FC = () => {
    const [customers, setCustomers] = useState<CustomerType[]>([])
    const [customerToDelete, setCustomerToDelete] = useState('')
    const [customerToView, setCustomerToView] = useState<CustomerType>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const res = await getCustomers()
                const sortedCustomers = res.data.sort((a: CustomerType, b: CustomerType) => a.name.localeCompare(b.name))
                setCustomers(sortedCustomers)
            } catch (err) {
                handleError(err, 'Something went wrong loading your customers. Please come back later.')
            }
            setLoading(false)
        }

        fetchCustomers()
    }, [])

    const handleCustomerDeleted = (deletedCustomerId: string) => {
        setCustomers(
            customers.filter((customer) => {
                return customer._id !== deletedCustomerId
            })
        )
    }

    return (
        <>
            <DeleteCustomerModal
                show={!!customerToDelete}
                onClose={() => setCustomerToDelete('')}
                onDeleted={handleCustomerDeleted}
                customerId={customerToDelete}
            />
            <ViewContactsModal
                show={!!customerToView}
                onClose={() => setCustomerToView(null)}
                contacts={customerToView?.contacts || []}
                title={
                    customerToView
                        ? `${customerToView.name} Contacts`
                        : 'Contacts'
                }
            />
            <h1>Customers</h1>
            <Page>
                <Section title="Customers List">
                    <SectionActions>
                        <ExportSpreadSheetButton
                            filePrefix='Customers-List'
                            requestFunction={exportCustomers}
                        />
                        <Link to="/app/customers/new">
                            <button className="primary">
                                + New Customer
                            </button>
                        </Link>
                    </SectionActions>
                    <SearchCustomersInput
                        onCustomersFound={setCustomers}
                    />
                    <CustomerTable
                        customers={customers}
                        loading={loading}
                        onViewContacts={setCustomerToView}
                        onDeleteCustomer={(c) => setCustomerToDelete(c._id)}
                    />
                </Section>
            </Page>
        </>
    )
}
