import React, { useMemo } from 'react'
import { FormikContextType } from 'formik'
import { EquipmentType } from '../../../server/models/Equipment'
import { Select } from '../Inputs/Select'

type Props = {
    equipment: EquipmentType[]
    formik: FormikContextType<any>
    name: string
    label: string
    value?: string
}

export const SelectEquipment: React.FC<Props> = ({
    equipment,
    formik,
    name,
    label,
    value
}) => {
    const options = useMemo(() => {
        const validEquipment = equipment.filter(
            (eqp) => {
                return eqp.serial && eqp.identifier
            }
        )

        return validEquipment.map((eqp) => {
            return {
                value: eqp.serial,
                label: `${eqp.serial} (${eqp.identifier})`
            }
        })
    }, [equipment])

    return (
        <Select
            label={label}
            options={options}
            formik={formik}
            value={value}
            name={name}
            disabled={options.length === 0}
            placeholder={
                options.length === 0
                    ? 'No valid equipment to select'
                    : 'Select Equipment...'
            }
        />
    )
}

