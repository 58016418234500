import React, { useEffect } from 'react'
import { FormikContextType } from 'formik'
import { Input } from '../Inputs/Input'
import { getAddressByZip } from '../../requests/address'
import './styles.css'


async function getCityStateFromZip(zip: string): Promise<{ city: string, state: string } | null> {
    try {
        const res = await getAddressByZip({ zip })
        return res.data
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}

type Props = {
    formik: FormikContextType<any>
    name: string
    label: string
    disabled?: boolean
}
export const Address: React.FC<Props> = ({ formik, name, label, disabled }) => {
    useEffect(() => {
        if (formik.values[name].zip?.length > 4) {
            getCityStateFromZip(
                formik.values[name].zip
            ).then((data) => {
                if (data?.city) {
                    formik.setFieldValue(`${name}.city`, data.city)
                }
                if (data?.state) {
                    formik.setFieldValue(`${name}.state`, data.state)
                }
            })
        }
    }, [formik.values[name].zip])

    return (
        <div className="address-container">
            {label.length > 0 && <label>{label}</label>}
            <Input
                label="Name"
                type="text"
                name={`${name}.name`}
                formik={formik}
                value={formik.values[name].name}
                disabled={disabled}
            />
            <Input
                label="Street"
                type="text"
                name={`${name}.street`}
                formik={formik}
                value={formik.values[name].street}
                disabled={disabled}
            />
            <div className="city-state-zip">
                <Input
                    label="City"
                    type="text"
                    name={`${name}.city`}
                    formik={formik}
                    value={formik.values[name].city}
                    disabled={disabled}
                />
                <Input
                    label="State"
                    type="text"
                    name={`${name}.state`}
                    formik={formik}
                    value={formik.values[name].state}
                    disabled={disabled}
                />
                <Input
                    label="Zip"
                    type="text"
                    name={`${name}.zip`}
                    formik={formik}
                    value={formik.values[name].zip}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

