import { useEffect } from 'react'

export const useScrollToHashAfterLoading = (loadingState: boolean) => {
    useEffect(() => {
        const scrollToElement = () => {
            const hash = window.location.hash
            if (hash) {
                const element = document.getElementById(hash.substring(1))
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' })
                }
            }
        }

        if (!loadingState) {
            scrollToElement()
        }
    }, [loadingState])
}
