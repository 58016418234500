import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getWorkOrder } from '../../../requests/workOrders'
import { WorkOrderType } from '../../../../server/models/WorkOrder'
import { handleError } from '../../../errors/handleError'
import { PrintTable } from '../../../components/PrintContainer'
import { Equipment } from '../../../components/PrintContainer/Equipment'
import { Status } from '../../../components/PrintContainer/Status'
import { Contacts } from '../../../components/PrintContainer/Contacts'
import { Warranties } from '../../../components/PrintContainer/Warranties'
import { Notes } from '../../../components/PrintContainer/Notes'
import { CustomerInfo } from '../../../components/PrintContainer/Customer'
import { formatAddress } from '../../../../utils/address/formatAddress'

export const PrintWorkOrder: React.FC = () => {
    const { workOrderId } = useParams()

    const [workOrder, setWorkOrder] = useState<WorkOrderType>()

    useEffect(() => {
        const fetchThisWorkOrder = async (): Promise<void> => {
            try {
                const res = await getWorkOrder(workOrderId)
                setWorkOrder(res.data)
            } catch (err) {
                handleError(
                    err,
                    'Something went wrong loading this work order. Please try again later.'
                )
            }
        }

        fetchThisWorkOrder()
    }, [workOrderId])

    if (!workOrder) {
        return null
    }

    return (
        <>
            <CustomerInfo customer={workOrder.customer} customerPo={workOrder.customerPo} />
            <PrintTable>
                <thead>
                    <tr>
                        <th colSpan={2}>Work Order Information</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Created At
                        </td>
                        <td>
                            {new Date(workOrder.createdAt).toLocaleDateString()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Last Updated At
                        </td>
                        <td>
                            {new Date(workOrder.updatedAt).toLocaleDateString()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Billing Address
                        </td>
                        <td>
                            {formatAddress(workOrder.billingAddress)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Service Address
                        </td>
                        <td>
                            {formatAddress(workOrder.serviceAddress)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Classification
                        </td>
                        <td>
                            {workOrder.classification}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Description
                        </td>
                        <td>
                            {workOrder.description}
                        </td>
                    </tr>
                </tbody>
            </PrintTable>
            <Contacts contacts={workOrder.contacts} />
            <Status statusTimeline={workOrder.statusTimeline} />
            <Equipment equipment={workOrder.equipment} />
            <Warranties warranties={workOrder.warranties} />
            <Notes />
        </>
    )
}

