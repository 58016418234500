import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { updateCustomer, getCustomer } from '../../../requests/customer'
import { handleError } from '../../../errors/handleError'
import { updateCustomerValidation } from '../../../../server/validation/customer'
import { emptyNewCustomer } from '../../../../utils/customers/emptyNewCustomer'
import { Form } from './Form'

export const EditCustomer: React.FC = () => {
    const navigate = useNavigate()
    const { customerId } = useParams()

    const formik = useFormik({
        initialValues: emptyNewCustomer,

        validationSchema: updateCustomerValidation,

        onSubmit: async (values) => {
            try {
                await updateCustomer(customerId, values)

                toast.success('Customer updated.')

                navigate('/app/customers')
            } catch (err) {
                handleError(err, 'Something went wrong updating this work order. Please try again later.')
            }
        }
    })

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const res = await getCustomer(customerId)
                formik.setValues(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong loading this Customer. Please try again later.')
            }
        }

        fetchCustomer()
    }, [customerId])

    return (
        <>
            <h1>Edit Customer</h1>
            <Form formik={formik} submitText='Save Changes' />
        </>
    )
}

