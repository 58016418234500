import { ServiceContractType } from '../../server/models/ServiceContract'

export const formatScheduleInterval = (sc: ServiceContractType): string => {
    const { serviceIntervalType, serviceIntervalAmount } = sc

    if (serviceIntervalAmount === 1) {
        return `Every ${serviceIntervalAmount} ${serviceIntervalType.replace('s','')}`
    }

    return `Every ${serviceIntervalAmount} ${serviceIntervalType}`
}
