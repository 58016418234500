import { AxiosResponse } from 'axios'
import { $axios } from './axios'

export const createNotification = (body: {
    workOrderId?: string
    serviceContractId?: string
    extraMessage: string
}): Promise<AxiosResponse<void>> => {
    return $axios.post('/notifications', body)
}
