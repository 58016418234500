import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getLatestStatus } from '../../../../utils/statusEvents/getLatestStatus'
import { formatScheduleInterval } from '../../../../utils/serviceContracts/formatScheduleInterval'
import { Dropdown } from '../../../components/Dropdown'
import { ServiceContractType } from '../../../../server/models/ServiceContract'
import { Empty } from '../../../components/Empty'
import { Table } from '../../../components/Table'
import { formatAddress } from '../../../../utils/address/formatAddress'
import { formatTechnicians } from '../../../../utils/assignedTechnicians/formatTechnicians'

const Thead = () => {
    return (
        <thead>
            <tr>
                <th>Customer</th>
                <th>Status</th>
                <th>Classification</th>
                <th>Schedule Interval</th>
                <th>Work Location</th>
                <th>Technician</th>
                <th></th>
            </tr>
        </thead>
    )
}

type Props = {
    serviceContracts: ServiceContractType[]
    loading: boolean
    onViewContacts: (project: ServiceContractType) => void
    onDeleteServiceContract: (project: ServiceContractType) => void
    onSendMessages: (serviceContract: ServiceContractType) => void
    onNotifyTechnicians: (serviceContract: ServiceContractType) => void
}
export const ServiceContractsTable: React.FC<Props> = ({
    serviceContracts,
    loading,
    onViewContacts,
    onDeleteServiceContract,
    onSendMessages,
    onNotifyTechnicians
}) => {
    if (loading) {
        return (
            <Table>
                <Thead />
                <tbody>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    if (serviceContracts.length === 0) {
        return <Empty>No service contracts to show at the moment.</Empty>
    }

    return (
        <Table>
            <Thead />
            <tbody>
                {
                    serviceContracts.map((serviceContract) => (
                        <tr key={serviceContract._id}>
                            <td>{serviceContract.customer.name}</td>
                            <td>{getLatestStatus(serviceContract.statusTimeline, serviceContract.createdAt).status}</td>
                            <td>{serviceContract.classification}</td>
                            <td>{formatScheduleInterval(serviceContract)}</td>
                            <td>{formatAddress(serviceContract.serviceAddress)}</td>
                            <td>{formatTechnicians(serviceContract)}</td>
                            <td>
                                <Dropdown
                                    items={[
                                        {
                                            label: 'Edit Service Contract',
                                            href: `/app/service-contracts/${serviceContract._id}`
                                        },
                                        {
                                            label: 'Print',
                                            href: `/print/service-contracts/${serviceContract._id}`,
                                            newTab: true
                                        },
                                        {
                                            label: 'Send Messages',
                                            onClick: () => onSendMessages(serviceContract)
                                        },
                                        {
                                            label: 'Notify Technicians',
                                            onClick: () => onNotifyTechnicians(serviceContract)
                                        },
                                        {
                                            label: 'View Contacts',
                                            onClick: () => onViewContacts(serviceContract)
                                        },
                                        {
                                            label: 'Assign Technicians',
                                            href: `/app/service-contracts/${serviceContract._id}#assigned-technicians`
                                        },
                                        {
                                            label: 'Delete Service Contract',
                                            onClick: () => onDeleteServiceContract(serviceContract)
                                        }
                                    ]}
                                />
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

