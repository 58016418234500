import React from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { createCustomerValidation } from '../../../../server/validation/customer'
import { createCustomer } from '../../../requests/customer'
import { emptyNewCustomer } from '../../../../utils/customers/emptyNewCustomer'
import { handleError } from '../../../errors/handleError'

// components
import { Form } from './Form'

export const AddCustomer: React.FC = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: emptyNewCustomer,
        validationSchema: createCustomerValidation,
        onSubmit: async (values) => {
            try {
                await createCustomer(values)
                toast.success('Customer successfully added.')
                navigate('/app/customers')
            } catch (err) {
                handleError(err, 'Something went wrong adding this customer. Please try again later.')
            }
        }
    })

    return (
        <>
            <h1>New Customer</h1>
            <Form formik={formik} submitText="Create Customer" />
        </>
    )
}
