import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Page, Section, SectionActions } from '../../../components/Page'
import { UserType } from '../../../../server/models/User'
import { getUsers } from '../../../requests/users'
import { EditUserModal } from './EditUser'
import { handleError } from '../../../errors/handleError'
import { Dropdown } from '../../../components/Dropdown'
import { AddUser } from './AddUser'
import { DeleteUserModal } from './DeleteUser'
import { Empty } from '../../../components/Empty'

export const Users: React.FC = () => {
    const [users, setUsers] = useState<UserType[]>([])
    const [addingNewUser, setAddingNewUser] = useState(false)
    const [editingUser, setEditingUser] = useState<UserType | null>(null)
    const [deletingUser, setDeletingUser] = useState<UserType | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await getUsers({ isTechnician: false })
                setUsers(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong loading users. Please come back later.')
            }
            setLoading(false)
        }

        fetchUsers()
    }, [])

    const handleUserUpdated = (updatedUser: UserType) => {
        setUsers(users.map((user) => user._id === updatedUser._id ? updatedUser : user))
    }

    return (
        <>
            <h1>Users</h1>
            <AddUser
                show={addingNewUser}
                isTechnician={false}
                onClose={() => setAddingNewUser(false)}
                onUserAdded={(newUser) => setUsers([...users, newUser])}
            />

            {editingUser && (
                <EditUserModal
                    show={!!editingUser}
                    onClose={() => setEditingUser(null)}
                    onUserUpdated={handleUserUpdated}
                    user={editingUser}
                />
            )}

            {deletingUser && (
                <DeleteUserModal
                    show={!!deletingUser}
                    onClose={() => setDeletingUser(null)}
                    onDelete={() => setUsers(users.filter((u) => u._id !== deletingUser._id))}
                    user={deletingUser}
                />
            )}

            <Page>
                <Section title="Manage Users">
                    <SectionActions>
                        <button className="primary" onClick={() => setAddingNewUser(true)}>
                            Add User
                        </button>
                    </SectionActions>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Last Login</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading && (<>
                                    <tr>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                </>)
                            }
                            {
                                users.map((user) => (
                                    <tr key={user._id}>
                                        <td>{user.name}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.lastLogin
                                            ? new Date(user.lastLogin).toLocaleString()
                                            : 'Never'
                                        }</td>
                                        <td>
                                            <Dropdown
                                                items={[
                                                    {
                                                        label: 'Edit',
                                                        onClick: () => setEditingUser(user)
                                                    },
                                                    {
                                                        label: 'Delete',
                                                        onClick: () => setDeletingUser(user)
                                                    }
                                                ]}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        users.length === 0 && <Empty>No users to show.</Empty>
                    }
                </Section>
            </Page>
        </>
    )
}
