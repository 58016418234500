import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { FormikContextType } from 'formik'
import { Modal, ModalActions } from '../Modal'
import { Select } from '../Inputs/Select'
import { getManufacturers, createManufacturer } from '../../requests/manufacturers'
import { Input } from '../Inputs/Input'
import { ManufacturerType } from '../../../server/models/Manufacturer'
import { createManufacturerValidation } from '../../../server/validation/manufacturers'
import { handleError } from '../../errors/handleError'
import { EditManufacturers } from './EditManufacturers'

type Props = {
    name: string
    formik: FormikContextType<any>
    value: string
}

export const Manufacturer: React.FC<Props> = ({
    name,
    formik,
    value
}) => {
    const [manufacturers, setManufacturers] = useState<ManufacturerType[]>([])
    const [addingNewManufacturer, setAddingNewManufacturer] = useState(false)

    const addManufacturerFormik = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: createManufacturerValidation,
        onSubmit: async (values) => {
            try {
                const res = await createManufacturer(values)

                formik.setFieldValue(name, res.data.name)

                setManufacturers([
                    ...manufacturers,
                    res.data
                ])

                toast.success('New manufacturer added.')
                setAddingNewManufacturer(false)
                addManufacturerFormik.resetForm()
            } catch (err) {
                handleError(err, 'Something went wrong adding this manufacturer. Please try again later.')
            }
        }
    })

    useEffect(() => {
        getManufacturers().then((res) => {
            setManufacturers(res.data)
        })
    }, [])

    const options = useMemo(() => {
        const givenValueExists = manufacturers.find((man) => man.name === value)

        const defaultOptions = [
            {
                value: 'new',
                label: 'Add New...'
            }
        ]

        if (!givenValueExists && value) {
            defaultOptions.push({
                value,
                label: value
            })
        }

        defaultOptions.push(
            ...manufacturers.map((option) => ({ value: option.name, label: option.name })).sort(
                (a, b) => a.value.localeCompare(b.value)
            )
        )

        return defaultOptions
    }, [manufacturers])

    useEffect(() => {
        if (value === 'new') {
            setAddingNewManufacturer(true)
        }
    }, [value])

    const onCancel = useCallback(() => {
        formik.setFieldValue(name, '')
        setAddingNewManufacturer(false)
        addManufacturerFormik.resetForm()
    }, [name])

    return (
        <>
            <Select
                label="Manufacturer"
                name={name}
                formik={formik}
                value={value}
                options={options}
                placeholder="Select Manufacturer..."
            />

            <EditManufacturers
                manufacturers={manufacturers}
                onManufacturerUpdated={() => {
                    getManufacturers().then((res) => {
                        setManufacturers(res.data)
                    })
                }}
            />

            <Modal isOpen={addingNewManufacturer} onRequestClose={onCancel}>
                <Input
                    type="text"
                    label="Manufacturer Name"
                    name="name"
                    placeholder="e.g. American Standard"
                    formik={addManufacturerFormik}
                />

                <ModalActions
                    onCancel={onCancel}
                    loading={addManufacturerFormik.isSubmitting}
                    onSubmit={addManufacturerFormik.submitForm}
                    submitText="Create"
                />
            </Modal>
        </>
    )
}

