import React from 'react'
import { PrintTable } from './index'

type Props = {}

export const Notes: React.FC<Props> = ({ }) => {
    return (
            <PrintTable>
                <thead>
                    <tr>
                        <th colSpan={2}>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td />
                    </tr>
                    <tr>
                        <td />
                    </tr>
                    <tr>
                        <td />
                    </tr>
                    <tr>
                        <td />
                    </tr>
                    <tr>
                        <td />
                    </tr>
                    <tr>
                        <td />
                    </tr>
                    <tr>
                        <td />
                    </tr>
                </tbody>
            </PrintTable>
    )
}

