import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page, Section, SectionActions } from '../../../components/Page'
import { WorkOrderType } from '../../../../server/models/WorkOrder'
import { getWorkOrders, exportWorkOrders } from '../../../requests/workOrders'
import { handleError } from '../../../errors/handleError'
import { DeleteWorkOrderModal } from './Delete'
import { WorkOrdersTable } from './Table'
import { ViewContactsModal } from '../../../components/Contacts/ViewContactsModal'
import { ExportSpreadSheetButton } from '../../../components/ExportSpreadSheetButton'
import { SendMessageModal } from '../MessageCenter/SendMessageModal'
import { SendNotificationsModal } from '../../../components/Notifications/SendNotificationsModal'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { StatusEventType } from './../../../../server/models/StatusEvent'

export const WorkOrders: React.FC = () => {
    const [workOrders, setWorkOrders] = useState<WorkOrderType[]>([])
    const [workOrderToView, setWorkOrderToView] = useState<WorkOrderType>()
    const [workOrderToSendMessagesFor, setWorkOrderToSendMessagesFor] = useState<WorkOrderType>()
    const [workOrderToDelete, setWorkOrderToDelete] = useState('')
    const [workOrderToNotify, setWorkOrderToNotify] = useState<WorkOrderType>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchWorkOrders = async () => {
            try {
                const res = await getWorkOrders()
                setWorkOrders(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong loading the work orders. Please try again later.')
            }

            setLoading(false)
        }

        fetchWorkOrders()
    }, [])

    const handleWorkOrderDeleted = (deletedWorkOrderId: string) => {
        setWorkOrders(
            workOrders.filter((workOrder) => {
                return workOrder._id !== deletedWorkOrderId
            })
        )
    }

    const getLatestStatus = (statusTimeline: StatusEventType[]) => {
        if (statusTimeline.length === 0) return 'open'
        const latestStatusEvent = statusTimeline.reduce((latest, current) => {
            return new Date(latest.statusObtainedAt) > new Date(current.statusObtainedAt) ? latest : current
        })
        return latestStatusEvent.status.toLowerCase()
    }

    const openWorkOrders = workOrders.filter(order => getLatestStatus(order.statusTimeline) !== 'closed')
    const closedWorkOrders = workOrders.filter(order => getLatestStatus(order.statusTimeline) === 'closed')

    return (
        <>
            <ViewContactsModal
                show={!!workOrderToView}
                onClose={() => setWorkOrderToView(null)}
                contacts={workOrderToView?.contacts || []}
                title={
                    workOrderToView
                        ? `${workOrderToView.customer.name} ${workOrderToView.classification} Work Order Contacts`
                        : 'Contacts'
                }
            />
            <DeleteWorkOrderModal
                show={!!workOrderToDelete}
                onClose={() => setWorkOrderToDelete('')}
                onDeleted={handleWorkOrderDeleted}
                workOrderId={workOrderToDelete}
            />
            <SendMessageModal
                isOpen={!!workOrderToSendMessagesFor}
                onClose={() => setWorkOrderToSendMessagesFor(null)}
                workOrder={workOrderToSendMessagesFor}
            />
            <SendNotificationsModal
                isOpen={!!workOrderToNotify}
                onClose={() => setWorkOrderToNotify(null)}
                workOrder={workOrderToNotify}
                serviceContract={null}
            />
            <h1>Work Orders</h1>
            <Page>
                <Section title="Work Orders List">
                    <SectionActions>
                        <ExportSpreadSheetButton
                            filePrefix='Work-Orders-List'
                            requestFunction={exportWorkOrders}
                        />
                        <Link to="/app/work-orders/new">
                            <button className="primary">
                                + New Work Order
                            </button>
                        </Link>
                    </SectionActions>
                    <Tabs>
                        <TabList>
                            <Tab>Open</Tab>
                            <Tab>Closed</Tab>
                        </TabList>

                        <TabPanel>
                            <WorkOrdersTable
                                workOrders={openWorkOrders}
                                loading={loading}
                                onViewContacts={setWorkOrderToView}
                                onDeleteWorkOrder={(w) => setWorkOrderToDelete(w._id)}
                                onSendMessages={setWorkOrderToSendMessagesFor}
                                onNotifyTechnicians={setWorkOrderToNotify}
                            />
                        </TabPanel>
                        <TabPanel>
                            <WorkOrdersTable
                                workOrders={closedWorkOrders}
                                loading={loading}
                                onViewContacts={setWorkOrderToView}
                                onDeleteWorkOrder={(w) => setWorkOrderToDelete(w._id)}
                                onSendMessages={setWorkOrderToSendMessagesFor}
                                onNotifyTechnicians={setWorkOrderToNotify}
                            />
                        </TabPanel>
                    </Tabs>
                </Section>
            </Page>
        </>
    )
}
