import * as yup from 'yup'
import { phoneRegExp } from './helpers'

export const createContactValidation = yup.object({
    title: yup.string().required('A contact title is required.'),
    name: yup.string().required('A contact name is required.'),
    email: yup.string().email('Must be a valid email address').notRequired(),
    phoneNumbers: yup.array().of(yup.object({
        name: yup.string().required('A phone number name is required.'),
        phone: yup.string().required('A phone number is required.').matches(phoneRegExp, 'Phone number is not valid.')
    })).required('A phone number is required.').default([])
})
