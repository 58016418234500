import React from 'react'
import QRCode from "react-qr-code"
import { PrintTable } from './index'
import { DocumentType } from '../../../server/models/Document'

type Props = {
    documents: DocumentType[]
}

export const Documents: React.FC<Props> = ({
    documents
}) => {
    if (!documents.length) {
        return null
    }
    return (
            <PrintTable>
                <thead>
                    <tr>
                        <th colSpan={2}>Documents</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        documents.map((document) => (
                            <tr key={document.fileUrl}>
                                <td>
                                    <QRCode
                                        value={document.fileUrl}
                                        size={150}
                                    />
                                </td>
                                <td>
                                    {document.name}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </PrintTable>
    )
}

