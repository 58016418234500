import { AxiosResponse } from 'axios'
import { $axios } from './axios'

export const getAddressByZip = (params: {
    zip: string
}): Promise<AxiosResponse<{ city: string, state: string }>> => {
    return $axios.get('/address-by-zip', {
        params
    })
}
