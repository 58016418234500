import React from 'react'
import { FormikContextType } from 'formik'
import { CreateCustomerType, UpdateCustomerType } from '../../../../server/models/Customer'

// components
import { AddContacts } from '../../../components/Contacts/AddContacts'
import { FormPage, Section, FormSection } from '../../../components/Page'
import { FloatingSubmit } from '../../../components/FloatingSubmit'
import { Input } from '../../../components/Inputs/Input'
import { Address } from '../../../components/Address'

type Props = {
    formik: FormikContextType<CreateCustomerType | UpdateCustomerType>
    submitText: string
}

export const Form: React.FC<Props> = ({
    formik,
    submitText
}) => {
    const sections = [
        {
            href: '#basic-information',
            title: 'Basic Information'
        },
        {
            href: '#contacts',
            title: 'Contacts'
        }
    ]

    return (
        <FormPage sections={sections}>
            <form onSubmit={formik.handleSubmit}>
                <FormSection title="Basic Information">
                    <Input
                        label="Name"
                        type="text"
                        name="name"
                        formik={formik}
                        placeholder="Company name or person's full name"
                        required
                    />

                    <Address
                        label="Billing Address"
                        name="address"
                        formik={formik}
                    />
                </FormSection>
                <Section title="Contacts">
                    <AddContacts formik={formik} />
                </Section>
                <FloatingSubmit
                    backHref="/app/customers"
                    backText="Back to customers"
                    submitText={submitText}
                    formik={formik}
                />
            </form>
        </FormPage>
    )
}

