import React, { useState, useEffect, useMemo } from 'react'
import { FormikContextType } from 'formik'
import { CustomerType } from '../../../server/models/Customer'
import { Select } from '../Inputs/Select'
import { getCustomers } from '../../requests/customer'
import { handleError } from '../../errors/handleError'

type Props = {
    required: boolean
    name: string
    label: string
    formik: FormikContextType<any>
}

export const SelectCustomer: React.FC<Props> = ({
    name,
    label,
    formik
}) => {
    const [loading, setLoading] = useState(true)
    const [customers, setCustomers] = useState<CustomerType[]>([])

    const options = useMemo(() => {
        if (loading) {
            return []
        }

        return customers.map((customer) => {
            return {
                value: customer._id,
                label: customer.name
            }
        })
    }, [loading, customers])

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const res = await getCustomers()
                setCustomers(res.data)
            } catch (err) {
                handleError(err, 'Failed to load customers. Please try again later.')
            }

            setLoading(false)
        }

        fetchCustomers()
    }, [])

    useEffect(() => {
        const value = formik.values[name]
        if (value) {
            const selectedCustomer = customers.find((customer) => customer._id === value)
            if (selectedCustomer) {
                formik.setFieldValue('billingAddress', selectedCustomer.address)

                const newContacts = [
                    ...formik.values.contacts,
                    ...selectedCustomer.contacts
                ].filter((contact, index, self) => {
                    return index === self.findIndex((t) => t.name === contact.name)
                })

                formik.setFieldValue('contacts', newContacts)
            }
        }
    }, [formik.values[name], customers])

    return (
        <Select
            label={label}
            name={name}
            formik={formik}
            placeholder={loading ? 'Preparing customers...' : 'Select Customer' }
            options={options}
        />
    )
}

