import { emptyNewStatusEvent } from '../statusEvents/emptyNewStatusEvent'
import { emptyNewEquipment } from '../equipment/emptyNewEquipment'
import { emptyNewWarranty } from '../warranties/emptyNewWarranty'
import { emptyNewContact } from '../contacts/emptyNewContact'
import { emptyAddress } from '../address/emptyAddress'

import { CreateServiceContractType } from '../../server/models/ServiceContract'

export const emptyNewServiceContract: CreateServiceContractType = {
    customer: '', // customer id
    proposalNumber: '',
    customerPo: '',

    serviceIntervalType: 'months',
    serviceIntervalAmount: 3,

    billingAddress: emptyAddress,
    serviceAddress: emptyAddress,

    classification: '',
    description: '',

    contacts: [emptyNewContact],

    statusTimeline: [emptyNewStatusEvent],

    equipment: [emptyNewEquipment],

    warranties: [emptyNewWarranty],

    assignedTechnicians: []
}
