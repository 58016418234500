import { AxiosResponse } from 'axios'
import { $axios } from './axios'
import { ManufacturerType } from '../../server/models/Manufacturer'

export const createManufacturer = (
    body: ManufacturerType
): Promise<AxiosResponse<ManufacturerType>> => {
    return $axios.post('/manufacturers', body)
}

export const updateManufacturer = (
    manufacturerId: string,
    body: { name: string }
): Promise<AxiosResponse<ManufacturerType>> => {
    return $axios.put(`/manufacturers/${manufacturerId}`, body)
}

export const deleteManufacturer = (
    manufacturerId: string
): Promise<AxiosResponse<void>> => {
    return $axios.delete(`/manufacturers/${manufacturerId}`)
}

export const getManufacturers = (): Promise<AxiosResponse<ManufacturerType[]>> => {
    return $axios.get('/manufacturers')
}
