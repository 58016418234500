
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { Modal, ModalActions } from '../../../components/Modal'
import { deleteUser } from '../../../requests/users'
import { handleError } from '../../../errors/handleError'
import { UserType } from '../../../../server/models/User'

type DeleteUserModalProps = {
    show: boolean
    onClose: () => void
    onDelete: () => void
    user: UserType
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
    show,
    onClose,
    onDelete,
    user
}) => {
    const formik = useFormik({
        initialValues: {},
        onSubmit: async () => {
            try {
                await deleteUser(user._id)
                onDelete()
                toast.success(`${user.name} has been deleted.`)
            } catch (err) {
                handleError(err, 'Something went wrong deleting this user. Please try again later.')
            }
            onClose()
        }
    })

    useEffect(() => {
        if (!show) {
            formik.resetForm()
        }
    }, [show])

    const onRequestClose = () => {
        if (formik.isSubmitting) {
            return
        }
        onClose()
    }

    return (
        <Modal isOpen={show} onRequestClose={onRequestClose}>
            <form onSubmit={formik.handleSubmit}>
                <p>Are you sure you want to delete {user.name}?</p>
                <ModalActions
                    onCancel={onRequestClose}
                    loading={formik.isSubmitting}
                    onSubmit={formik.handleSubmit}
                    submitText="Delete"
                />
            </form>
        </Modal>
    )
}
