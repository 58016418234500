import { AxiosResponse } from 'axios'
import { $axios } from './axios'
import { UserType } from '../../server/models/User'

export const createUser = (body: {
    name: string
    phone: string
    isTechnician: boolean
}): Promise<AxiosResponse<UserType>> => {
    return $axios.post('/user', body)
}

export const getUsers = (params: {
    isTechnician?: boolean
} = {}): Promise<AxiosResponse<UserType[]>> => {
    return $axios.get('/user', { params })
}

export const deleteUser = (id: string): Promise<AxiosResponse<{
    message: string
}>> => {
    return $axios.delete(`/user/${id}`)
}

export const updateUser = (id: string, body: {
    name: string
}): Promise<AxiosResponse<UserType>> => {
    return $axios.put(`/user/${id}`, body)
}
