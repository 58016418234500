import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import { getProject, updateProject } from '../../../requests/projects'
import { createProjectValidation } from '../../../../server/validation/projects'
import { handleError } from '../../../errors/handleError'
import { ProjectForm } from './Form'
import { PageHeader } from '../../../components/Page'
import { emptyNewProject } from '../../../../utils/projects/emptyNewProject'

export const EditProject: React.FC = () => {
    const { projectId } = useParams()

    const formik = useFormik({
        initialValues: emptyNewProject,

        validationSchema: createProjectValidation,

        onSubmit: async (values) => {
            try {
                await updateProject(projectId, values)

                toast.success('Project saved.')
            } catch (err) {
                handleError(err, 'Something went wrong updating this Project. Please try again later.')
            }
        }
    })

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const res = await getProject(projectId)

                formik.setValues({
                    ...res.data,
                    customer: res.data.customer._id,
                })
            } catch (err) {
                handleError(err, 'Something went wrong loading this Project. Please try again later.')
            }
        }

        fetchProject()
    }, [projectId])

    return (
        <>
            <PageHeader title="Edit Project">
                <a href={`/print/projects/${projectId}`} target="_blank" rel="noopener noreferrer">
                    <button className="primary">
                        Print
                    </button>
                </a>
            </PageHeader>
            <ProjectForm
                formik={formik}
                submitButtonText="Save Project"
            />
        </>
    )
}
