import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Card } from '../../../components/Page'
import { getServiceContracts } from '../../../requests/serviceContracts'
import { ServiceContractType } from '../../../../server/models/ServiceContract'
import { handleError } from '../../../errors/handleError'
import { getLatestStatus } from '../../../../utils/statusEvents/getLatestStatus'

export const ServiceContractsCard: React.FC = () => {
    const [serviceContracts, setServiceContracts] = useState<ServiceContractType[]>([])
    const [loading, setLoading] = useState(true)

    const title = 'Service Contract Updates'

    useEffect(() => {
        const fetchServiceContracts = async () => {
            try {
                const res = await getServiceContracts({ limit: 5, open: true })
                setServiceContracts(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong loading your service contracts. Please try again later.')
            }

            setLoading(false)
        }

        fetchServiceContracts()
    }, [])

    if (loading) {
        return (
            <Card title={title}>
                <table>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                        </tr>
                        <tr>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                        </tr>
                        <tr>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                            <td><Skeleton /></td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        )
    }
    if (!serviceContracts.length) {
        return (
            <Card title={title}>
                No service contracts to show.
            </Card>
        )
    }

    return (
        <Card title={title}>
            <table>
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        serviceContracts.map((sc) => (
                            <tr key={sc._id}>
                                <td>{sc.customer.name}</td>
                                <td>{getLatestStatus(sc.statusTimeline, sc.createdAt).status}</td>
                                <td>{ new Date(sc.updatedAt).toLocaleString()}</td>
                                <td>
                                    <Link to={`/app/service-contracts/${sc._id}`}>
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </Card>
    )
}
