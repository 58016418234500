import * as yup from 'yup'
import { createContactValidation } from './contact'
import { createStatusEventValidation } from './statusEvent'
import { createEquipmentValidation } from './equipment'
import { createWarrantyValidation } from './warranty'
import { addressValidation } from './address'
import { assignTechnicianValidation } from './assignedTechnician'

export const createWorkOrderValidation = yup.object({
    customer: yup.string().required('A customer must be selected for this work order.'),

    proposalNumber: yup.string().notRequired(),
    customerPo: yup.string().required('A customer PO number is required.'),

    billingAddress: addressValidation,
    serviceAddress: addressValidation,

    classification: yup.string().required('A classification for this work order is required.'),
    description: yup.string().required('A description for this work order is required.'),

    contacts: yup.array().of(createContactValidation),

    statusTimeline: yup.array().of(createStatusEventValidation),

    equipment: yup.array().of(createEquipmentValidation),

    warranties: yup.array().of(createWarrantyValidation),

    assignedTechnicians: yup.array().of(assignTechnicianValidation)
})

export const updateWorkOrderValidation = createWorkOrderValidation
