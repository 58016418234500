import React, { useEffect, useState } from 'react'
import { FormikContextType } from 'formik'

import {
    FormPage,
    Section,
    FormSection,
} from '../../../components/Page'
import { FloatingSubmit } from '../../../components/FloatingSubmit'
import { Input } from '../../../components/Inputs/Input'
import { TextArea } from '../../../components/Inputs/TextArea'
import { Checkbox } from '../../../components/Inputs/Checkbox'
import { Address } from '../../../components/Address'
import { SelectCustomer } from '../../../components/Customers/SelectCustomer'
import { ServiceAddress } from '../../../components/ServiceAddress'
import { SelectClassification } from '../../../components/projects/SelectClassification'
import { DateInput } from '../../../components/Inputs/Date'

import { AddDocuments } from '../../../components/Documents/AddDocuments'
import { AddEquipment } from '../../../components/Equipment/AddEquipment'
import { AddWarranties } from '../../../components/Warranties/AddWarranties'
import { AddContacts } from '../../../components/Contacts/AddContacts'
import { EditStatusTimeline } from '../../../components/StatusEvents/EditStatusTimeline'
import { MessagesSection } from '../MessageCenter/MessagesSection'

import { CreateProjectType, UpdateProjectType, ProjectType } from '../../../../server/models/Project'

type Props = {
    formik: FormikContextType<CreateProjectType | UpdateProjectType>
    submitButtonText: string
    hideMessages?: boolean
}

export const ProjectForm: React.FC<Props> = ({
    formik,
    submitButtonText,
    hideMessages
}) => {
    const [
        billingAndServiceAddressesSame,
        setBillingAndServiceAddressesSame
    ] = useState(false)

    useEffect(() => {
        if (!billingAndServiceAddressesSame) {
            return
        }

        if (formik.values.billingAddress !== formik.values.serviceAddress) {
            formik.setFieldValue('serviceAddress', formik.values.billingAddress)
        }
    }, [billingAndServiceAddressesSame, formik])

    return (
        <FormPage
            sections={[
                {
                    href: '#owner-information',
                    title: 'Owner Information'
                },
                {
                    href: '#project-information',
                    title: 'Project Information'
                },
                {
                    href: '#documents',
                    title: 'Documents'
                },
                {
                    href: '#contacts',
                    title: 'Contacts'
                },
                {
                    href: '#project-status',
                    title: 'Project Status Events'
                },
                {
                    href: '#equipment',
                    title: 'Equipment'
                },
                {
                    href: '#warranties',
                    title: 'Warranties'
                },

                hideMessages ? null :
                {
                    href: '#messages',
                    title: 'Messages'
                }
            ]}
        >
            <form onSubmit={formik.handleSubmit}>
                <FormSection title="Owner Information">
                    <SelectCustomer
                        label="Customer Owner"
                        name="customer"
                        formik={formik}
                        required
                    />

                    <Input
                        label="LCM Proposal No."
                        type="text"
                        name="proposalNumber"
                        formik={formik}
                    />

                    <Input
                        label="Permit Number"
                        type="text"
                        name="permitNumber"
                        formik={formik}
                    />

                    <DateInput
                        label="Permit Date"
                        value={formik.values.permitDate}
                        name="permitDate"
                        formik={formik}
                    />

                    <Input
                        label="Customer PO"
                        type="text"
                        name="customerPo"
                        formik={formik}
                        required
                    />

                    <Address
                        label="Billing Address"
                        name="billingAddress"
                        formik={formik}
                    />

                    <ServiceAddress
                        formik={formik}
                        disabled={billingAndServiceAddressesSame}
                    />

                    <Checkbox
                        onChange={() => setBillingAndServiceAddressesSame(!billingAndServiceAddressesSame)}
                        name="billingAndServiceAddressesSame"
                        label="Billing address is same as service address"
                        checked={billingAndServiceAddressesSame}
                    />
                </FormSection>

                <FormSection title="Project Information">
                    <SelectClassification
                        label="Project Classification"
                        name="classification"
                        formik={formik}
                        required
                    />

                    <TextArea
                        label="Project Description"
                        name="description"
                        formik={formik}
                        required
                    />
                </FormSection>

                <FormSection title="Documents">
                        <AddDocuments formik={formik} />
                </FormSection>

                <Section title="Contacts">
                    <AddContacts formik={formik} />
                </Section>

                <FormSection title="Project Status">
                    <EditStatusTimeline formik={formik} />
                </FormSection>

                <Section title="Equipment">
                    <AddEquipment formik={formik} />
                </Section>

                <Section title="Warranties">
                    <AddWarranties formik={formik} />
                </Section>

                { !hideMessages && (
                    <Section title="Messages">
                        <MessagesSection
                            project={formik.values as unknown as ProjectType}
                        />
                    </Section>
                )}

                <FloatingSubmit
                    backHref="/app/projects"
                    backText="Back to Projects"
                    submitText={submitButtonText}
                    formik={formik}
                />
            </form>
        </FormPage>
    )
}

