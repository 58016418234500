import { emptyNewStatusEvent } from '../statusEvents/emptyNewStatusEvent'
import { emptyNewEquipment } from '../equipment/emptyNewEquipment'
import { emptyNewWarranty } from '../warranties/emptyNewWarranty'
import { emptyNewContact } from '../contacts/emptyNewContact'
import { emptyAddress } from '../address/emptyAddress'

import { CreateProjectType } from '../../server/models/Project'

export const emptyNewProject: CreateProjectType = {
    customer: '', // customer id
    proposalNumber: '',
    customerPo: '',

    permitDate: undefined,
    permitNumber: '',

    billingAddress: emptyAddress,
    serviceAddress: emptyAddress,

    classification: '',
    description: '',

    contacts: [emptyNewContact],

    statusTimeline: [emptyNewStatusEvent],

    equipment: [emptyNewEquipment],

    warranties: [emptyNewWarranty],

    documents: []
}
