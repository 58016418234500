import * as yup from 'yup'

export const createWarrantyValidation = yup.object({
    company: yup.string().required('Company name of this warranty is required.'),
    type: yup.string().required('The warranty type is required.'),
    confirmationNumber: yup.string().required('Confirmation number is required.'),
    expirationDate: yup.date().required('Expiration date is required.'),
    equipmentSerial: yup.string().required('Equipment of this warranty is required.'),
    notify: yup.array().of(yup.string())
})
