import React, { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { Modal, ModalActions } from '../../../components/Modal'
import { TextArea } from '../../../components/Inputs/TextArea'
import { Input } from '../../../components/Inputs/Input'
import { DateInput } from '../../../components/Inputs/Date'
import { MessageType, CreateMessageType } from '../../../../server/models/Messages'
import { WorkOrderType } from '../../../../server/models/WorkOrder'
import { ContactType } from '../../../../server/models/Contact'
import { ProjectType } from '../../../../server/models/Project'
import { WarrantyType } from '../../../../server/models/Warranty'
import { ServiceContractType } from '../../../../server/models/ServiceContract'
import { ListMessages } from './ListMessages'
import {
    createMessage,
    getMessages,
    getMessageContactList
} from '../../../requests/messages'
import { handleError } from '../../../errors/handleError'
import './styles.css'

const formatDate = (inDate: Date): string => {
    return new Date(inDate).toLocaleDateString()
}

function isDateInPast(date: Date): boolean {
    const currentDate = new Date();
    // Set the time of currentDate to 00:00:00 for accurate comparison
    currentDate.setHours(0, 0, 0, 0);

    // Return true if the input date is before the current date
    return (new Date(date)).getTime() < currentDate.getTime();
}

type Props = {
    isOpen: boolean
    onClose: () => void
    onMessagesSent?: (sentMessages: MessageType[]) => void

    // pass ids to these.
    workOrder?: WorkOrderType
    project?: ProjectType
    serviceContract?: ServiceContractType

    onExistingMessagesLoaded?: (messages: MessageType[]) => void
}

// when given a work order, project, or service contract....
//      - show warranties for this item.
//      - fetch and show messages for this item
//

type SelectedContact = {
    name: string
    phone?: string
    email?: string
}
export const SendMessageModal: React.FC<Props> = ({
    isOpen,
    onClose,
    onMessagesSent,
    workOrder,
    project,
    serviceContract,
    onExistingMessagesLoaded
}) => {
    const [existingMessages, setExistingMessages] = useState<MessageType[]>([])
    const [contactOptions, setContactOptions] = useState<ContactType[]>([])
    const [searchResults, setSearchResults] = useState<ContactType[]>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedContacts, setSelectedContacts] = useState<Array<{
        name: string
        phone?: string
        email?: string
    }>>([])

    const tomorrow = useMemo(() => {
        const today = new Date();
        const tmr = new Date(today);
        tmr.setDate(tmr.getDate() + 1);
        return tmr
    }, [])

    const warrantiesToDisplay = useMemo((): WarrantyType[] => {
        if (!workOrder && !serviceContract && !project) {
            return []
        }

        return workOrder?.warranties ?? serviceContract?.warranties ?? project?.warranties
    }, [workOrder, serviceContract, project])

    useEffect(() => {
        if (!workOrder && !serviceContract && !project) {
            return
        }

        const fetchExistingMessagesForItem = async () => {
            const params: {
                project?: string
                workOrder?: string
                serviceContract?: string
            } = {}

            if (workOrder) {
                params.workOrder = workOrder._id
            }

            if (project) {
                params.project = project._id
            }

            if (serviceContract) {
                params.serviceContract = serviceContract._id
            }

            try {
                const res = await getMessages(params)
                setExistingMessages(res.data)
                if (onExistingMessagesLoaded) {
                    onExistingMessagesLoaded(res.data)
                }
            } catch (err) {
                handleError(err, 'Failed to get existing messages for this item. Please try again later.')
                setExistingMessages([])
            }
        }

        fetchExistingMessagesForItem()
    }, [workOrder, serviceContract, project])

    useEffect(() => {
        if (!isOpen) {
            return
        }

        const fetchAllContacts = async () => {
            const params: {
                projectId?: string
                serviceContractId?: string
                workOrderId?: string
            } = {}

            if (workOrder) {
                params.workOrderId = workOrder._id
            }

            if (project) {
                params.projectId = project._id
            }

            if (serviceContract) {
                params.serviceContractId = serviceContract._id
            }

            try {
                const res = await getMessageContactList(params)
                return setContactOptions(res.data)
            } catch (err) {
                console.error('Failed to fetch all available contacts:', err)
            }
        }

        fetchAllContacts()
    }, [workOrder, serviceContract, project, isOpen])

    const formik = useFormik({
        initialValues: {
            message: '',
            sendAt: undefined,
            recurrenceIntervalDays: undefined
        },
        onSubmit: async (values) => {
            const defaultBody: Partial<CreateMessageType> = {}

            if (!selectedContacts.length) {
                return toast.error('No contact recipients selected.')
            }

            if (values.recurrenceIntervalDays) {
                defaultBody.recurrenceIntervalDays = values.recurrenceIntervalDays
            }

            if (values.sendAt) {
                if (isDateInPast(values.sendAt)) {
                    return toast.error('Scheduled date must be in the future.')
                }

                defaultBody.sendAt = values.sendAt
            }

            if (project) {
                defaultBody.project = project._id
            } else if (workOrder) {
                defaultBody.workOrder = workOrder._id
            } else if (serviceContract) {
                defaultBody.serviceContract = serviceContract._id
            }

            const sentMessages: MessageType[] = []

            await Promise.all(
               selectedContacts.map(async (contactToMessage) => {
                   const body: CreateMessageType = {
                       ...defaultBody,
                       message: values.message
                   }

                   if (contactToMessage.email) {
                       body.emailTo = contactToMessage.email
                   }

                   if (contactToMessage.phone) {
                        body.textTo = contactToMessage.phone
                   }

                   try {
                       const res = await createMessage(body)
                       sentMessages.push(res.data)
                   } catch (err) {
                       handleError(
                           err,
                           `Something went wrong sending this message to ${contactToMessage.name}. Please try again later.`
                       )
                   }
               })
            )

            if (sentMessages.length) {
                const messageParts: string[] = [
                    `${sentMessages.length} ${sentMessages.length === 1 ? 'message' : 'messages'} successfully`
                ]
                if (values.sendAt) {
                    messageParts.push(' scheduled.')
                } else {
                    messageParts.push(' sent.')
                }

                toast.success(messageParts.join(''))

                if (onMessagesSent) {
                    onMessagesSent(sentMessages)
                }
            }

            formik.resetForm()
            setSelectedContacts([])

            onClose()
        }
    })

    const onRequestClose = () => {
        if (!formik.isSubmitting) {
            onClose()
        }
    }

    const handleContactSelected = (newContact: SelectedContact) => {
        setSearchTerm('')

        const alreadySelected = selectedContacts.find((contact) => {
            return (
                contact.name === newContact.name && (
                    contact.email === newContact.email || contact.phone === newContact.phone
                )
            )
        })

        if (alreadySelected) {
            toast.error('Contact is already selected.')
            return
        }

        setSelectedContacts([
            ...selectedContacts,
            newContact
        ])
    }

    const handleContactRemoved = (selectedContactToRemove: SelectedContact) => {
        setSelectedContacts(
            selectedContacts.filter(selectedContact => {
                if (selectedContact.name !== selectedContactToRemove.name ) {
                    return true
                }

                if (selectedContact.email) {
                    return selectedContact.email !== selectedContactToRemove.email
                }

                if (selectedContact.phone) {
                    return selectedContact.phone !== selectedContactToRemove.phone
                }
            })
        )
    }

    const handleRemoveScheduledMessage = async (messageToDelete: MessageType) => {
        setExistingMessages(
            existingMessages.filter((msg) => msg._id !== messageToDelete._id)
        )
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toLowerCase())
    }

    useEffect(() => {
        if (!searchTerm) {
            return setSearchResults([])
        }

        const results = contactOptions.filter((contact) => {
            const toSearch = contact.name.toLowerCase()
            return toSearch.includes(searchTerm)
        })

        // first 5 results
        setSearchResults(results.slice(0, 5))
    }, [searchTerm])

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className="send-message-modal">
                { warrantiesToDisplay.length > 0 && (
                    <details>
                        <summary><h4>View Warranties ({warrantiesToDisplay.length})</h4></summary>
                        <ul className="existing-warranties">
                            { warrantiesToDisplay.map((warranty) => (
                                <li key={warranty.confirmationNumber}>
                                    <p>Type: {warranty.type}</p>
                                    <p>Company: {warranty.company}</p>
                                    <p>Expiration: {warranty.expirationDate ? formatDate(warranty.expirationDate) : 'No expiration'}</p>
                                </li>
                            )) }
                        </ul>
                    </details>
                ) }

                {existingMessages.length > 0 && (
                    <details>
                        <summary><h4>View Messages ({existingMessages.length})</h4></summary>
                        <ListMessages
                            messages={existingMessages}
                            onMessageRemove={handleRemoveScheduledMessage}
                        />
                    </details>
                )}

                <form onSubmit={formik.handleSubmit}>
                    <label htmlFor="contact">
                        Search Contacts
                        <input
                            type="search"
                            name="contact"
                            placeholder="Search for a contact..."
                            onChange={handleSearch}
                            value={searchTerm}
                        />
                        <div className="contact-results">
                        { searchResults.map((contact) => (
                            <>
                                {!!contact.email && (
                                    <button
                                        type="button"
                                        onClick={() => handleContactSelected({
                                            name: contact.name,
                                            email: contact.email
                                        })
                                        }
                                    >
                                        {contact.title} {contact.name} ({contact.email})
                                    </button>
                                )}
                                {
                                    contact.phoneNumbers.map(({ phone, name }) => (
                                        <button
                                            type="button"
                                            onClick={
                                                () => handleContactSelected({
                                                    name: contact.name,
                                                    phone
                                                })
                                            }
                                        >
                                            {contact.title} {contact.name} ({name}: {phone})
                                        </button>
                                    ))
                                }
                            </>
                        ))}
                        </div>
                    </label>
                    <label htmlFor="contact">
                        <select
                            name="contact"
                            onChange={(e) => handleContactSelected(JSON.parse(e.target.value))}
                            value=""
                            disabled={contactOptions.length === 0}
                        >
                            <option disabled value="">Select from list of Contacts...</option>
                            {contactOptions.map((contact) => (
                                <React.Fragment key={contact._id}>
                                    { !!contact.email && (
                                        <option value={JSON.stringify({ name: contact.name, email: contact.email})}>{contact.title} {contact.name} ({contact.email})</option>
                                    )}
                                    {
                                        contact.phoneNumbers.map(({ phone, name }) => (
                                            <option key={phone} value={JSON.stringify({ name: contact.name, phone })}>{contact.title} {contact.name} ({ name } - {phone})</option>
                                        ))
                                    }
                                </React.Fragment>
                            ))}
                        </select>
                    </label>
                    {selectedContacts.length > 0 && (
                        <ul className="selected-contacts">
                            {selectedContacts.map((contact) => (
                                <li>
                                    {contact.name} ({contact.email ?? contact.phone})
                                    <button type="button" title="Remove Selected Contact" onClick={() => handleContactRemoved(contact)}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_194_977)"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00005 7.06056L8.65155 9.71256C8.79224 9.85325 8.98307 9.9323 9.18205 9.9323C9.38102 9.9323 9.57185 9.85325 9.71255 9.71256C9.85324 9.57186 9.93229 9.38103 9.93229 9.18206C9.93229 8.98308 9.85324 8.79225 9.71255 8.65156L7.06005 6.00006L9.71205 3.34856C9.78168 3.27889 9.83691 3.19619 9.87458 3.10518C9.91225 3.01417 9.93163 2.91663 9.93161 2.81813C9.93159 2.71963 9.91216 2.6221 9.87445 2.5311C9.83673 2.44011 9.78146 2.35744 9.7118 2.28781C9.64213 2.21817 9.55943 2.16294 9.46842 2.12527C9.37741 2.0876 9.27987 2.06822 9.18137 2.06824C9.08287 2.06826 8.98534 2.08769 8.89435 2.1254C8.80335 2.16312 8.72068 2.21839 8.65105 2.28806L6.00005 4.93956L3.34855 2.28806C3.27939 2.21639 3.19666 2.15922 3.10518 2.11987C3.01369 2.08052 2.91529 2.05978 2.81571 2.05887C2.71612 2.05796 2.61735 2.07689 2.52516 2.11455C2.43298 2.15222 2.34921 2.20787 2.27876 2.27826C2.20831 2.34864 2.15258 2.43236 2.11482 2.52451C2.07707 2.61666 2.05805 2.71541 2.05886 2.815C2.05968 2.91458 2.08032 3.01301 2.11959 3.10453C2.15885 3.19605 2.21595 3.27884 2.28755 3.34806L4.94005 6.00006L2.28805 8.65156C2.14735 8.79225 2.06831 8.98308 2.06831 9.18206C2.06831 9.38103 2.14735 9.57186 2.28805 9.71256C2.42874 9.85325 2.61957 9.9323 2.81855 9.9323C3.01752 9.9323 3.20835 9.85325 3.34905 9.71256L6.00005 7.06006V7.06056Z" fill="black"/> </g> <defs> <clipPath id="clip0_194_977"> <rect width="12" height="12" fill="white"/> </clipPath> </defs> </svg>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}
                    <TextArea
                        formik={formik}
                        label="Message"
                        name="message"
                        required
                    />
                    <DateInput
                        formik={formik}
                        name="sendAt"
                        label="Schedule When To Send (Optional)"
                        value={formik.values.sendAt}
                        minDate={tomorrow}
                    />

                    <Input
                        type="number"
                        name="recurrenceIntervalDays"
                        label="Recurring Interval in Days (Optional)"
                        placeholder="e.g. Send every 7 days..."
                        formik={formik}
                        min={0}
                        note="Leave blank or zero to send this message only once"
                    />

                    <ModalActions
                        submitText={selectedContacts.length === 1 ? 'Send Message' : 'Send Messages'}
                        onCancel={onClose}
                        onSubmit={formik.handleSubmit}
                        loading={formik.isSubmitting}
                    />
                </form>
            </div>
        </Modal>
    )
}
