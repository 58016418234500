import { WorkOrderType } from '../../server/models/WorkOrder'
import { ServiceContractType } from '../../server/models/ServiceContract'

export const formatTechnicians = (item: WorkOrderType | ServiceContractType) => {
    const countTechnicians = item.assignedTechnicians?.length || 0

    if (!countTechnicians) {
        return 'None'
    }

    if (countTechnicians === 1) {
        return '1 Assigned Technician'
    }

    return `${countTechnicians} Assigned Technicians`
}
