import React from 'react'
import { FormikContextType, FormikProvider, FieldArray } from 'formik'
import { UploadZone } from '../UploadZone'
import { Input } from '../Inputs/Input'
import { DocumentType } from '../../../server/models/Document'
import './styles.css'

type Props = {
    formik: FormikContextType<any>
}

export const AddDocuments: React.FC<Props> = ({ formik }) => {
    return (
        <FormikProvider value={formik}>
            <FieldArray name="documents">
                {({ push, remove }) => (
                    <div className="documents">
                        Add Documents
                        { formik.values.documents.map((document: DocumentType, index: number) => (
                            <div className="one-document" key={document.fileUrl}>
                                <Input
                                    label=""
                                    type="text"
                                    name={`documents.${index}.name`}
                                    formik={formik}
                                    value={document.name}
                                    placeholder="Add document file name..."
                                />
                                <a href={document.fileUrl} target="_blank" rel="noopener noreferrer">
                                    <button type="button" className="secondary">
                                        View
                                    </button>
                                </a>
                                <button type="button" className="secondary" onClick={() => remove(index)}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <UploadZone
                            name="new_document"
                            label=""
                            onFileUploaded={
                                (fileUrl) => push({
                                    fileUrl,
                                    name: `Unnamed Document #${formik.values.documents.length + 1}`
                                })
                            }
                        />
                    </div>
                )}
            </FieldArray>
        </FormikProvider>
    )
}

