import React from 'react'
import DatePicker from 'react-date-picker'
import { FormikContextType } from 'formik'
import { getValueByPath } from '../../../utils/getValueByPath'
import './styles.css'

type Props = {
    name: string
    label: string
    required?: boolean
    disabled?: boolean
    formik: FormikContextType<any>
    value: Date
    minDate?: Date
}
export const DateInput: React.FC<Props> = ({
    name,
    label,
    required,
    disabled,
    formik,
    value,
    minDate
}) => {
    return (
      <label htmlFor={name}>
          { label }
          <DatePicker
              className="date-picker"
              name={name}
              onChange={(value) => formik.setFieldValue(name, value)}
              onBlur={formik.handleBlur}
              required={required}
              disabled={disabled}
              value={value}
              isOpen={false}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              openCalendarOnFocus={false}
              minDate={minDate}
          />
          <sub className="error">
             {getValueByPath(formik.touched, name) && getValueByPath(formik.errors, name)
                ? getValueByPath(formik.errors, name).toString()
                : ''
              }
          </sub>
      </label>
    )
}
