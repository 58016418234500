import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { updateWorkOrder, getWorkOrder } from '../../../requests/workOrders'
import { handleError } from '../../../errors/handleError'
import { updateWorkOrderValidation } from '../../../../server/validation/workorder'
import { emptyNewWorkOrder } from '../../../../utils/workOrders/emptyNewWorkOrder'
import { WorkOrderForm } from './Form'
import { PageHeader } from '../../../components/Page'
import { useScrollToHashAfterLoading } from '../../../hooks/useScrollToHashAfterLoading'

export const EditWorkOrder: React.FC = () => {
    const { workOrderId } = useParams()

    const [loading, setLoading] = useState(true)
    useScrollToHashAfterLoading(loading)

    const formik = useFormik({
        initialValues: emptyNewWorkOrder,

        validationSchema: updateWorkOrderValidation,

        onSubmit: async (values) => {
            try {
                await updateWorkOrder(workOrderId, values)

                toast.success('Work Order saved.')
            } catch (err) {
                handleError(err, 'Something went wrong updating this work order. Please try again later.')
            }
        }
    })

    useEffect(() => {
        const fetchWorkOrder = async () => {
            setLoading(true)

            try {
                const res = await getWorkOrder(workOrderId)
                formik.setValues({
                    ...res.data,
                    customer: res.data.customer._id
                })
            } catch (err) {
                handleError(err, 'Something went wrong loading this Work Order. Please try again later.')
            }

            setLoading(false)
        }

        fetchWorkOrder()
    }, [workOrderId])

    return (
        <>
            <PageHeader title="Edit Work Order">
                <a href={`/print/work-orders/${workOrderId}`} target="_blank" rel="noopener noreferrer">
                    <button className="primary">
                        Print
                    </button>
                </a>
            </PageHeader>
            <WorkOrderForm formik={formik} submitText='Save Changes' />
        </>
    )
}

