import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page, Section, SectionActions } from '../../../components/Page'
import { ServiceContractType } from '../../../../server/models/ServiceContract'
import { getServiceContracts, exportServiceContracts } from '../../../requests/serviceContracts'
import { handleError } from '../../../errors/handleError'
import { DeleteServiceContractModal } from './Delete'
import { ServiceContractsTable } from './Table'
import { ViewContactsModal } from '../../../components/Contacts/ViewContactsModal'
import { ExportSpreadSheetButton } from '../../../components/ExportSpreadSheetButton'
import { SendMessageModal } from '../MessageCenter/SendMessageModal'
import { SendNotificationsModal } from '../../../components/Notifications/SendNotificationsModal'

export const ServiceContracts: React.FC = () => {
    const [serviceContracts, setServiceContracts] = useState<ServiceContractType[]>([])
    const [
        serviceContractToDelete, 
        setServiceContractToDelete
    ] = useState('')
    const [
        serviceContractToView,
        setServiceContractToView
    ] = useState<ServiceContractType>()
    const [serviceContractToSendMessagesFor, setServiceContractToSendMessagesFor] = useState<ServiceContractType>()
    const [
        serviceContractToNotify,
        setServiceContractToNotify
    ] = useState<ServiceContractType>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchServiceContracts = async () => {
            try {
                const res = await getServiceContracts()
                setServiceContracts(res.data)
            } catch (err) {
                handleError(err, 'Something went wrong loading the work orders. Please try again later.')
            }

            setLoading(false)
        }

        fetchServiceContracts()
    }, [])

    const handleServiceContractDeleted = (deletedServiceContractId: string) => {
        setServiceContracts(
            serviceContracts.filter((serviceContract) => {
                return serviceContract._id !== deletedServiceContractId
            })
        )
    }

    return (
        <>
            <DeleteServiceContractModal
                show={!!serviceContractToDelete}
                onClose={() => setServiceContractToDelete('')}
                onDeleted={handleServiceContractDeleted}
                serviceContractId={serviceContractToDelete}
            />
            <ViewContactsModal
                show={!!serviceContractToView}
                onClose={() => setServiceContractToView(null)}
                contacts={serviceContractToView?.contacts || []}
                title={
                    serviceContractToView
                        ? `${serviceContractToView.customer.name} ${serviceContractToView.classification} Service Contract Contacts`
                        : 'Contacts'
                }
            />
            <SendMessageModal
                isOpen={!!serviceContractToSendMessagesFor}
                onClose={() => setServiceContractToSendMessagesFor(null)}
                workOrder={serviceContractToSendMessagesFor}
            />
            <SendNotificationsModal
                isOpen={!!serviceContractToNotify}
                onClose={() => setServiceContractToNotify(null)}
                workOrder={null}
                serviceContract={serviceContractToNotify}
            />
            <h1>Service Contracts</h1>
            <Page>
                <Section title="Service Contracts List">
                    <SectionActions>
                        <ExportSpreadSheetButton
                            requestFunction={exportServiceContracts}
                            filePrefix="Service-Contracts-List"
                        />
                        <Link to="/app/service-contracts/new">
                            <button className="primary">
                                + New Service Contract
                            </button>
                        </Link>
                    </SectionActions>
                    <ServiceContractsTable
                        serviceContracts={serviceContracts}
                        loading={loading}
                        onViewContacts={setServiceContractToView}
                        onDeleteServiceContract={(sc) => setServiceContractToDelete(sc._id)}
                        onSendMessages={setServiceContractToSendMessagesFor}
                        onNotifyTechnicians={setServiceContractToNotify}
                    />
                </Section>
            </Page>
        </>
    )
}

