import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getLatestStatus } from '../../../../utils/statusEvents/getLatestStatus'
import { Dropdown } from '../../../components/Dropdown'
import { ProjectType } from '../../../../server/models/Project'
import { Empty } from '../../../components/Empty'
import { Table } from '../../../components/Table'
import { formatAddress } from '../../../../utils/address/formatAddress'

const Thead = () => {
    return (
        <thead>
            <tr>
                <th>Customer</th>
                <th>Status</th>
                <th>Classification</th>
                <th>Work Location</th>
                <th></th>
            </tr>
        </thead>
    )
}

type Props = {
    projects: ProjectType[]
    loading: boolean
    onViewContacts: (project: ProjectType) => void
    onDeleteProject: (project: ProjectType) => void
    onSendMessages: (project: ProjectType) => void
}
export const ProjectsTable: React.FC<Props> = ({
    projects,
    loading,
    onViewContacts,
    onDeleteProject,
    onSendMessages
}) => {
    if (loading) {
        return (
            <Table>
                <Thead />
                <tbody>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    if (projects.length === 0) {
        return <Empty>No projects to show at the moment.</Empty>
    }

    return (
        <Table>
            <Thead />
            <tbody>
                {
                    projects.map((project) => (
                        <tr key={project._id}>
                            <td>{project.customer.name}</td>
                            <td>{getLatestStatus(project.statusTimeline, project.createdAt).status}</td>
                            <td>{project.classification}</td>
                            <td>{formatAddress(project.serviceAddress)}</td>
                            <td>
                                <Dropdown
                                    items={[
                                        {
                                            label: 'Edit Project',
                                            href: `/app/projects/${project._id}`
                                        },
                                        {
                                            label: 'Print',
                                            href: `/print/projects/${project._id}`,
                                            newTab: true
                                        },
                                        {
                                            label: 'Send Messages',
                                            onClick: () => onSendMessages(project)
                                        },
                                        {
                                            label: 'View Contacts',
                                            onClick: () => onViewContacts(project)
                                        },
                                        {
                                            label: 'Delete Project',
                                            onClick: () => onDeleteProject(project)
                                        }
                                    ]}
                                />
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

