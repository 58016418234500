import React, { useEffect, useState } from 'react'
import { FormikContextType } from 'formik'
import {
    CreateServiceContractType,
    UpdateServiceContractType,
    ServiceContractType
} from '../../../../server/models/ServiceContract'
import { FormPage, Section, FormSection } from '../../../components/Page'
import { FloatingSubmit } from '../../../components/FloatingSubmit'
import { Input } from '../../../components/Inputs/Input'
import { TextArea } from '../../../components/Inputs/TextArea'
import { Checkbox } from '../../../components/Inputs/Checkbox'
import { Address } from '../../../components/Address'
import { ServiceAddress } from '../../../components/ServiceAddress'
import { SelectCustomer } from '../../../components/Customers/SelectCustomer'
import { ScheduleIntervalInput } from '../../../components/ServiceContracts/ScheduleIntervalInput'
import { SelectClassification } from '../../../components/ServiceContracts/SelectClassification'
import { AddEquipment } from '../../../components/Equipment/AddEquipment'
import { AddWarranties } from '../../../components/Warranties/AddWarranties'
import { AddContacts } from '../../../components/Contacts/AddContacts'
import { EditStatusTimeline } from '../../../components/StatusEvents/EditStatusTimeline'
import { AssignTechnicians } from '../../../components/AssignTechnicians'
import { MessagesSection } from '../MessageCenter/MessagesSection'

type Props = {
    formik: FormikContextType<CreateServiceContractType | UpdateServiceContractType>
    submitButtonText: string
    hideMessages?: boolean
}

export const ServiceContractForm: React.FC<Props> = ({
    formik,
    submitButtonText,
    hideMessages
}) => {
    const [businessAndServiceAddressesSame, setBusinessAndServiceAddressesSame] = useState(false)

    const sections = [
        {
            href: '#owner-information',
            title: 'Owner Information'
        },
        {
            href: '#service-contract-information',
            title: 'Service Information'
        },
        {
            href: '#assigned-technicians',
            title: 'Assigned Technicians'
        },
        {
            href: '#contacts',
            title: 'Contacts'
        },
        {
            href: '#service-contract-status',
            title: 'Status Timeline'
        },
        {
            href: '#equipment',
            title: 'Equipment'
        },
        {
            href: '#warranties',
            title: 'Warranties'
        },
        hideMessages ? null :
        {
            href: '#messages',
            title: 'Messages'
        }
    ]

    useEffect(() => {
        if (!businessAndServiceAddressesSame) {
            return
        }

        if (formik.values.billingAddress !== formik.values.serviceAddress) {
            formik.setFieldValue('serviceAddress', formik.values.billingAddress)
        }
    },[formik, businessAndServiceAddressesSame])

    return (
        <FormPage sections={sections}>
            <form onSubmit={formik.handleSubmit}>
                <FormSection title="Owner Information">
                    <SelectCustomer
                        label="Customer Owner"
                        name="customer"
                        formik={formik}
                        required
                    />

                    <Input
                        label="LCM Proposal No."
                        type="text"
                        name="proposalNumber"
                        formik={formik}
                    />

                    <Input
                        label="Customer PO"
                        type="text"
                        name="customerPo"
                        formik={formik}
                        required
                    />

                    <Address
                        label="Billing Address"
                        name="billingAddress"
                        formik={formik}
                    />

                    <ServiceAddress
                        formik={formik}
                        disabled={businessAndServiceAddressesSame}
                    />

                    <Checkbox
                        onChange={() => setBusinessAndServiceAddressesSame(!businessAndServiceAddressesSame)}
                        name="businessAndServiceAddressesSame"
                        label="Billing address is same as service address"
                        checked={businessAndServiceAddressesSame}
                    />
                </FormSection>
                <FormSection title="Service Contract Information">
                    <SelectClassification
                        label="Service Contract Classification"
                        name="classification"
                        formik={formik}
                        required
                    />

                    <TextArea
                        label="Service Contract Description"
                        name="description"
                        formik={formik}
                        required
                    />
                    <ScheduleIntervalInput formik={formik} />
                </FormSection>

                <AssignTechnicians formik={formik} />

                <Section title="Contacts">
                    <AddContacts formik={formik} />
                </Section>

                <Section title="Service Contract Status">
                    <FormSection title="">
                        <EditStatusTimeline formik={formik} />
                    </FormSection>
                </Section>

                <Section title="Equipment">
                    <AddEquipment formik={formik} />
                </Section>

                <Section title="Warranties">
                    <AddWarranties formik={formik} />
                </Section>

                { !hideMessages && (<Section title="Messages">
                    <MessagesSection
                        serviceContract={formik.values as unknown as ServiceContractType}
                    />
                </Section>)}

                <FloatingSubmit
                    backHref="/app/service-contracts"
                    backText="Back to service contracts"
                    submitText={submitButtonText}
                    formik={formik}
                />
            </form>
        </FormPage>
    )
}
