import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getWorkOrders } from '../../../requests/workOrders'
import { WorkOrderType } from '../../../../server/models/WorkOrder'

type Props = {
    customerId: string
}

export const CustomerWorkOrdersCell: React.FC<Props> = ({
    customerId
}) => {
    const [customerWorkOrders, setCustomerWorkOrders] = useState<WorkOrderType[]>([])
    const [failed, setFailed] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchWorkOrders = async (): Promise<void> => {
            try {
                const res = await getWorkOrders({ customer: customerId })
                setCustomerWorkOrders(res.data)
            } catch (err) {
                setFailed(true)
            }

            setLoading(false)
        }

        fetchWorkOrders()
    }, [customerId])

    if (loading) {
        return <Skeleton width="112px" />
    }

    if (failed) {
        return (
            <>Failed to fetch work orders</>
        )
    }

    return (
        <>{customerWorkOrders.length} Work Order{customerWorkOrders.length === 1 ? '' : 's'}</>
    )
}

