import React, { useMemo, useEffect, useState } from 'react'
import { FormikContextType } from 'formik'
import { FormSection } from '../Page'
import { UserType } from '../../../server/models/User'
import { getUsers } from '../../requests/users'

import './styles.css'

type Props = {
    formik: FormikContextType<any>
}

export const AssignTechnicians: React.FC<Props> = ({
    formik
}) => {
    const [
        availableUsers,
        setAvailableUsers
    ] = useState<UserType[]>([])

    const currentValue = useMemo(() => {
        if (!formik.values?.assignedTechnicians) {
            return []
        }

        return formik.values.assignedTechnicians as { user: string }[]
    }, [formik])

    const userOptions = useMemo(() => {
        return availableUsers.filter((user) => {
            const alreadySelected = currentValue.find((technician) => technician.user === user._id)
            return !alreadySelected
        }).map((user) => ({
            label: `${user.name} (${user.phone})`,
            value: user._id
        }))
    }, [availableUsers, currentValue])

    const assignedTechnicians = useMemo(() => {
        const selectedUsers: UserType[] = []

        if (!currentValue) {
            return selectedUsers
        }

        for (let i = 0; i < currentValue.length; i++) {
            const userId = currentValue[i].user
            const user = availableUsers.find((user) => user._id === userId)
            if (user) {
                selectedUsers.push(user)
            }
        }
        return selectedUsers
    }, [currentValue, availableUsers])

    useEffect(() => {
        const fetchUsers = async (): Promise<void> => {
            try {
                const res = await getUsers()

                setAvailableUsers(
                    res.data.filter((user) => user.name)
                )
            } catch (err) {
                setAvailableUsers([])
            }
        }

        fetchUsers()
    }, [])

    const removeTechnician = (user: UserType) => {
        formik.setFieldValue(
            'assignedTechnicians',
            currentValue.filter((tech) => {
                return user._id !== tech.user
            })
        )
    }

    const handleOptionSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        formik.setFieldValue('assignedTechnicians', [
            ...currentValue,
            { user: e.target.value }
        ])
    }

    return (
        <FormSection title="Assigned Technicians">
            <div className="assign-technicians">
                {
                    assignedTechnicians.map((selectedTechnician) => (
                        <div className="one-assigned-technician">
                            <button type="button" onClick={() => removeTechnician(selectedTechnician)}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_194_977)"> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00006 9.41395L11.5354 12.95C11.723 13.1375 11.9774 13.2429 12.2427 13.2429C12.508 13.2429 12.7625 13.1375 12.9501 12.95C13.1377 12.7624 13.243 12.5079 13.243 12.2426C13.243 11.9773 13.1377 11.7229 12.9501 11.5353L9.41339 7.99995L12.9494 4.46462C13.0422 4.37173 13.1159 4.26146 13.1661 4.14012C13.2163 4.01877 13.2422 3.88872 13.2421 3.75738C13.2421 3.62605 13.2162 3.49601 13.1659 3.37468C13.1156 3.25336 13.0419 3.14313 12.9491 3.05028C12.8562 2.95744 12.7459 2.8838 12.6246 2.83357C12.5032 2.78334 12.3732 2.7575 12.2418 2.75753C12.1105 2.75756 11.9805 2.78346 11.8591 2.83375C11.7378 2.88404 11.6276 2.95773 11.5347 3.05062L8.00006 6.58595L4.46473 3.05062C4.37253 2.95507 4.26222 2.87883 4.14024 2.82637C4.01826 2.7739 3.88705 2.74625 3.75427 2.74504C3.6215 2.74382 3.48981 2.76906 3.36689 2.81928C3.24397 2.86951 3.13228 2.94371 3.03834 3.03756C2.94441 3.1314 2.8701 3.24302 2.81976 3.36589C2.76942 3.48876 2.74406 3.62043 2.74515 3.75321C2.74624 3.88599 2.77377 4.01722 2.82612 4.13925C2.87847 4.26128 2.9546 4.37166 3.05006 4.46395L6.58673 7.99995L3.05073 11.5353C2.86313 11.7229 2.75774 11.9773 2.75774 12.2426C2.75774 12.5079 2.86313 12.7624 3.05073 12.95C3.23832 13.1375 3.49276 13.2429 3.75806 13.2429C4.02336 13.2429 4.2778 13.1375 4.46539 12.95L8.00006 9.41328V9.41395Z" fill="black"/> </g> <defs> <clipPath id="clip0_194_977"> <rect width="16" height="16" fill="white"/> </clipPath> </defs> </svg>
                            </button>
                            <span>{selectedTechnician.name}</span>
                        </div>
                    ))
                }
                <select name="select-technician" onChange={handleOptionSelected} value="">
                    <option selected disabled value="">Select technician...</option>
                    {
                        userOptions.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))
                    }
                </select>
            </div>
        </FormSection>
    )
}

