import React from 'react'
import { toast } from 'react-toastify'
import { DeleteConfirmationModal } from '../../../components/DeleteConfirmationModal'
import { deleteCustomer } from '../../../requests/customer'
import { handleError } from '../../../errors/handleError'

type Props = {
    show: boolean
    onClose: () => void
    onDeleted: (customerId: string) => void
    customerId: string
}

export const DeleteCustomerModal: React.FC<Props> = ({
    show,
    onClose,
    onDeleted,
    customerId
}) => {
    const onDelete = async () => {
        try {
            await deleteCustomer(customerId)
            onDeleted(customerId)
            toast.success('Customer deleted.')
        } catch (err) {
            handleError(err, 'Something went wrong deleting this customer. Please try again later.')
        }

        onClose()
    }

    return (
        <DeleteConfirmationModal
            show={show}
            onCancel={onClose}
            onDelete={onDelete}
            itemName='Customer'
        />
    )
}

