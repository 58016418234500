import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getProject } from '../../../requests/projects'
import { ProjectType } from '../../../../server/models/Project'
import { handleError } from '../../../errors/handleError'
import { PrintTable } from '../../../components/PrintContainer'
import { Equipment } from '../../../components/PrintContainer/Equipment'
import { Status } from '../../../components/PrintContainer/Status'
import { Contacts } from '../../../components/PrintContainer/Contacts'
import { Warranties } from '../../../components/PrintContainer/Warranties'
import { Notes } from '../../../components/PrintContainer/Notes'
import { Documents } from '../../../components/PrintContainer/Documents'
import { CustomerInfo } from '../../../components/PrintContainer/Customer'
import { formatAddress } from '../../../../utils/address/formatAddress'

export const PrintProject: React.FC = () => {
    const { projectId } = useParams()

    const [project, setProject] = useState<ProjectType>()

    useEffect(() => {
        const fetchThisProject = async (): Promise<void> => {
            try {
                const res = await getProject(projectId)
                setProject(res.data)
            } catch (err) {
                handleError(
                    err,
                    'Something went wrong loading this project. Please try again later.'
                )
            }
        }

        fetchThisProject()
    }, [projectId])

    if (!project) {
        return null
    }

    return (
        <>
            <CustomerInfo customer={project.customer} customerPo={project.customerPo} />
            <PrintTable>
                <thead>
                    <tr>
                        <th colSpan={2}>Project Info</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Created At
                        </td>
                        <td>
                            {new Date(project.createdAt).toLocaleDateString()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Last Updated At
                        </td>
                        <td>
                            {new Date(project.updatedAt).toLocaleDateString()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Permit Number
                        </td>
                        <td>
                            {project.permitNumber}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Permit Date
                        </td>
                        <td>
                            {project.permitDate
                                ? new Date(project.permitDate).toLocaleDateString()
                                : 'No permit date'
                            }
                        </td>
                    </tr>
                    { !!project.proposalNumber && (
                        <tr>
                            <td>
                                Proposal Number
                            </td>
                            <td>
                                {project.proposalNumber}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            Billing Address
                        </td>
                        <td>
                            {formatAddress(project.billingAddress)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Service Address
                        </td>
                        <td>
                            {formatAddress(project.serviceAddress)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Classification
                        </td>
                        <td>
                            {project.classification}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Description
                        </td>
                        <td>
                            {project.description}
                        </td>
                    </tr>
                </tbody>
            </PrintTable>
            <Contacts contacts={project.contacts} />
            <Status statusTimeline={project.statusTimeline} />
            <Equipment equipment={project.equipment} />
            <Warranties warranties={project.warranties} />
            <Documents documents={project.documents} />
            <Notes />
        </>
    )
}

