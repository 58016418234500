import React from 'react'
import { PrintTable } from './index'
import { StatusEventType } from '../../../server/models/StatusEvent'

type Props = {
    statusTimeline: StatusEventType[]
}

export const Status: React.FC<Props> = ({ statusTimeline }) => {
    return (
        <PrintTable>
            <thead>
                <tr>
                    <th colSpan={2}>Status Timeline</th>
                </tr>
            </thead>
            <tbody>
                {
                    statusTimeline.map((status) => (
                        <tr>
                            <td>{status.status}</td>
                            <td>{new Date(status.statusObtainedAt).toLocaleDateString()}</td>
                        </tr>
                    ))
                }
            </tbody>
        </PrintTable>
    )
}

