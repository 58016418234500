import { WarrantyType } from '../../server/models/Warranty'

export const emptyNewWarranty: WarrantyType = {
    equipmentSerial: '',
    company: '',
    type: '',
    confirmationNumber: '',
    expirationDate: undefined,
    notify: []
}
