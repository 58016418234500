import React from 'react'
import { ContactType } from '../../../server/models/Contact'
import { Modal } from '../../components/Modal'
import "./styles.css"

type Props = {
    show: boolean
    onClose: () => void
    contacts: ContactType[]
    title: string
}

export const ViewContactsModal: React.FC<Props> = ({
    show,
    onClose,
    contacts,
    title
}) => {
    return (
        <Modal isOpen={show} onRequestClose={onClose}>
            <h2>{title}</h2>
            {
                contacts.length === 0 && <p>No contacts to show.</p>
            }
            {
                contacts.map((contact) => (
                    <div key={contact._id} className="one-contact-info">
                        <h3>{contact.title}</h3>
                        <p>{contact.name}</p>
                        <ul>
                            { !!contact.email && <li>{contact.email}</li> }
                            {
                                contact.phoneNumbers.map(({ name, phone }) => (
                                    <li key={phone}>
                                        {name}: {phone}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                ))
            }
            <button className="primary" type="button" onClick={onClose}>
                Close
            </button>
        </Modal>
    )
}

