import * as yup from 'yup'
import { createContactValidation } from './contact'
import { createStatusEventValidation } from './statusEvent'
import { createEquipmentValidation } from './equipment'
import { createWarrantyValidation } from './warranty'
import { serviceIntervalTypes } from '../../utils/serviceContracts/intervalTypes'
import { addressValidation } from './address'
import { assignTechnicianValidation } from './assignedTechnician'

export const createServiceContractValidation = yup.object({
    customer: yup.string().required('A customer must be selected for this service contract.'),

    serviceIntervalType: yup.string().oneOf(serviceIntervalTypes).required(),
    serviceIntervalAmount: yup.number().min(0, 'Must be greater than zero').required(),

    proposalNumber: yup.string().notRequired(),
    customerPo: yup.string().required('A customer PO number is required.'),

    billingAddress: addressValidation.required('Billing address is required'),
    serviceAddress: addressValidation.required('Service address is required'),

    classification: yup.string().required('A classification for this service contract is required.'),
    description: yup.string().required('A description for this service contract is required.'),

    contacts: yup.array().of(createContactValidation),

    statusTimeline: yup.array().of(createStatusEventValidation),

    equipment: yup.array().of(createEquipmentValidation),

    warranties: yup.array().of(createWarrantyValidation),

    assignedTechnicians: yup.array().of(assignTechnicianValidation)
})

export const updateServiceContractValidation = createServiceContractValidation
