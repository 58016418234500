import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Modal, ModalActions } from '../../../components/Modal'
import { Input } from '../../../components/Inputs/Input'
import { updateUser } from '../../../requests/users'
import { handleError } from '../../../errors/handleError'
import { UserType } from '../../../../server/models/User'
import { updateUserValidation } from '../../../../server/validation/user'

type Props = {
    show: boolean
    onClose: () => void
    onUserUpdated: (updatedUser: UserType) => void
    user: UserType
}

export const EditUserModal: React.FC<Props> = ({
    show,
    onClose,
    onUserUpdated,
    user
}) => {
    const formik = useFormik({
        initialValues: {
            name: user.name
        },
        validationSchema: updateUserValidation,
        onSubmit: async (values) => {
            try {
                const res = await updateUser(user._id, values)
                onUserUpdated(res.data)
                toast.success(`${values.name} has been updated.`)
                onClose()
            } catch (err) {
                handleError(err, 'Something went wrong updating this user. Please try again later.')
            }
        }
    })

    useEffect(() => {
        if (!show) {
            formik.resetForm()
        }
    }, [show])

    const onRequestClose = () => {
        if (formik.isSubmitting) {
            return
        }
        onClose()
    }

    return (
        <Modal isOpen={show} onRequestClose={onRequestClose}>
            <form onSubmit={formik.handleSubmit}>
                <p>Note: If you want to change the phone number, please delete this user and create a new one.</p>
                <Input
                    type="text"
                    label="Name"
                    name="name"
                    formik={formik}
                />

                <ModalActions
                    onCancel={onRequestClose}
                    loading={formik.isSubmitting}
                    onSubmit={formik.handleSubmit}
                    submitText="Update"
                />
            </form>
        </Modal>
    )
}
