import * as yup from 'yup'
import { createContactValidation } from './contact'
import { createStatusEventValidation } from './statusEvent'
import { createEquipmentValidation } from './equipment'
import { createWarrantyValidation } from './warranty'
import { createDocumentValidation } from './documents'
import { addressValidation } from './address'

export const createProjectValidation = yup.object({
    customer: yup.string().required('A customer must be selected for this project.'),

    proposalNumber: yup.string().notRequired(),
    customerPo: yup.string().required('A customer PO number is required.'),

    permitNumber: yup.string().notRequired(),
    permitDate: yup.date().notRequired(),

    billingAddress: addressValidation.required('Billing address is required'),
    serviceAddress: addressValidation.required('Service address is required'),

    classification: yup.string().required('A classification for this project is required.'),
    description: yup.string().required('A description for this project is required.'),

    contacts: yup.array().of(createContactValidation),

    statusTimeline: yup.array().of(createStatusEventValidation),

    equipment: yup.array().of(createEquipmentValidation),

    warranties: yup.array().of(createWarrantyValidation),

    documents: yup.array().of(createDocumentValidation)
})

export const updateProjectValidation = createProjectValidation
