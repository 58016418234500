import React from 'react'
import { FormikContextType } from 'formik'

type Props = {
    name: string
    label: string
    required?: boolean
    placeholder?: string
    formik: FormikContextType<any>
}
export const SelectClassification: React.FC<Props> = ({
    name,
    label,
    required,
    formik
}) => {
    const value = formik.values[name]

    return (
      <label htmlFor={name}>
          { label }
          <select
              name={name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required={required}
              value={value}
          >
              <option value="" disabled selected={!value || value === ""}>Select Classification...</option>
              <optgroup label="Design">
                  <option value="Design Commercial">Design Commercial</option>
                  <option value="Design Residential">Design Residential</option>
              </optgroup>

              <optgroup label="Electrical">
                  <option value="Electrical Commercial Service">Electrical Commercial Service</option>
                  <option value="Electrical Commercial Install">Electrical Commercial Install</option>
                  <option value="Electrical Commercial Service">Electrical Commercial Service</option>
              </optgroup>

              <optgroup label="General Construction">
                  <option value="General Construction Service">General Construction Service</option>
                  <option value="New Construction Commercial">New Construction Commercial</option>
                  <option value="New Construction Residential">New Construction Residential</option>
                  <option value="Remodeling Commercial">Remodeling Commercial</option>
                  <option value="Remodeling Residential">Remodeling Residential</option>
              </optgroup>

              <optgroup label="HVAC">
                  <option value="HVAC Commercial Install">HVAC Commercial Install</option>
                  <option value="HVAC Commercial Service">HVAC Commercial Service</option>
                  <option value="HVAC Religious">HVAC Religious</option>
                  <option value="HVAC Residential Install">HVAC Residential Install</option>
                  <option value="HVAC Residential Service">HVAC Residential Service</option>
                  <option value="HVAC Service Parts">HVAC Service Parts</option>
                  <option value="Refrigeration">Refrigeration</option>
              </optgroup>

              <optgroup label="Plumbing">
                  <option value="Plumbing Commercial Install">Plumbing Commercial Install</option>
                  <option value="Plumbing Commercial Service">Plumbing Commercial Service</option>
                  <option value="Plumbing Residential Install">Plumbing Residential Install</option>
                  <option value="Plumbing Residential Service">Plumbing Residential Service</option>
              </optgroup>
          </select>
          <sub className="error">
             {formik.touched[name] && formik.errors[name]
                ? formik.errors[name].toString()
                : ''
              }
          </sub>
      </label>
    )
}
