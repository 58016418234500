import React, { useState } from 'react'
import { FormSection } from '../../../components/Page'
import { SendMessageModal } from '../MessageCenter/SendMessageModal'
import { ListMessages } from '../MessageCenter/ListMessages'
import { MessageType } from '../../../../server/models/Messages'

import { ServiceContractType } from '../../../../server/models/ServiceContract'
import { WorkOrderType } from '../../../../server/models/WorkOrder'
import { ProjectType } from '../../../../server/models/Project'

import './styles.css'

type Props = {
    serviceContract?: ServiceContractType
    workOrder?: WorkOrderType
    project?: ProjectType
}

export const MessagesSection: React.FC<Props> = ({
    serviceContract,
    workOrder,
    project
}) => {
    const [messageModalOpen, setMessageModalOpen] = useState(false)
    const [existingMessages, setExistingMessages] = useState<MessageType[]>([])

    return (
        <>
            <FormSection title="">
                {
                    existingMessages.length > 0 && (
                        <ListMessages messages={existingMessages} onMessageRemove={(messageToRemove) => {
                            setExistingMessages(existingMessages.filter((msg) => msg._id !== messageToRemove._id))
                        }} />
                    )
                }
                <button className="primary" type="button" onClick={() => setMessageModalOpen(true)}>
                    Prepare messages
                </button>
            </FormSection>
            <SendMessageModal
                isOpen={messageModalOpen}
                onClose={() => setMessageModalOpen(false)}

                serviceContract={serviceContract}
                workOrder={workOrder}
                project={project}

                onExistingMessagesLoaded={setExistingMessages}
                onMessagesSent={(sentMessages) => {
                    setExistingMessages([...existingMessages, ...sentMessages])
                }}
            />
        </>
    )
}

