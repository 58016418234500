import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getLatestStatus } from '../../../../utils/statusEvents/getLatestStatus'
import { Dropdown } from '../../../components/Dropdown'
import { WorkOrderType } from '../../../../server/models/WorkOrder'
import { Empty } from '../../../components/Empty'
import { Table } from '../../../components/Table'
import { formatAddress } from '../../../../utils/address/formatAddress'
import { formatTechnicians } from '../../../../utils/assignedTechnicians/formatTechnicians'

const Thead = () => {
    return (
        <thead>
            <tr>
                <th>Customer</th>
                <th>Status</th>
                <th>Classification</th>
                <th>Work Location</th>
                <th>Technician(s)</th>
                <th></th>
            </tr>
        </thead>
    )
}

type Props = {
    workOrders: WorkOrderType[]
    loading: boolean
    onViewContacts: (project: WorkOrderType) => void
    onDeleteWorkOrder: (project: WorkOrderType) => void
    onSendMessages: (workOrder: WorkOrderType) => void
    onNotifyTechnicians: (workOrder: WorkOrderType) => void
}
export const WorkOrdersTable: React.FC<Props> = ({
    workOrders,
    loading,
    onViewContacts,
    onDeleteWorkOrder,
    onSendMessages,
    onNotifyTechnicians
}) => {
    if (loading) {
        return (
            <Table>
                <Thead />
                <tbody>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    if (workOrders.length === 0) {
        return <Empty>No service contracts to show at the moment.</Empty>
    }


    return (
        <Table>
            <Thead />
            <tbody>
                {
                    workOrders.map((workOrder) => (
                        <tr key={workOrder._id}>
                            <td>{workOrder.customer.name}</td>
                            <td>{getLatestStatus(workOrder.statusTimeline, workOrder.createdAt).status}</td>
                            <td>{workOrder.classification}</td>
                            <td>{formatAddress(workOrder.serviceAddress)}</td>
                            <td>{formatTechnicians(workOrder)}</td>
                            <td>
                                <Dropdown
                                    items={[
                                        {
                                            label: 'Edit Work Order',
                                            href: `/app/work-orders/${workOrder._id}`
                                        },
                                        {
                                            label: 'Print',
                                            href: `/print/work-orders/${workOrder._id}`,
                                            newTab: true
                                        },
                                        {
                                            label: 'Send Messages',
                                            onClick: () => onSendMessages(workOrder)
                                        },
                                        {
                                            label: 'Notify Technicians',
                                            onClick: () => onNotifyTechnicians(workOrder)
                                        },
                                        {
                                            label: 'View Contacts',
                                            onClick: () => onViewContacts(workOrder)
                                        },
                                        {
                                            label: 'Assign Technicians',
                                            href: `/app/work-orders/${workOrder._id}#assigned-technicians`
                                        },
                                        {
                                            label: 'Delete Work Order',
                                            onClick: () => onDeleteWorkOrder(workOrder)
                                        }
                                    ]}
                                />
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
}

