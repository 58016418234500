import React, { useMemo } from 'react'
import { ProjectsCard } from './ProjectsCard'
import { WorkOrdersCard } from './WorkOrdersCard'
import { ServiceContractsCard } from './ServiceContractsCard'
import { WarrantiesCard } from './WarrantiesCard'

import './styles.css'

export const Dashboard: React.FC = () => {
    const todaysDate = useMemo(() => {
        const today = new Date()
        return today.toDateString()
    }, [])

    return (
        <div>
            <h1>Dashboard, {todaysDate}</h1>
            <div className="dashboard-container">
                <ProjectsCard />

                <WorkOrdersCard />

                <WarrantiesCard />

                <ServiceContractsCard />
            </div>
        </div>
    )
}
