import React from 'react'
import { ContactType } from '../../../server/models/Contact'
import './styles.css'

type Props = {
    contacts: ContactType[]
    onClick: () => void
}

export const ViewContactsButton: React.FC<Props> = ({
    contacts,
    onClick
}) => {
    return (
        <button className="view-contacts" type="button" onClick={onClick}>
            {contacts?.length ?? 'No'} Contact{contacts?.length === 1 ? '' : 's'}
        </button>
    )
}

