import * as yup from 'yup'
import { createContactValidation } from './contact'
import { addressValidation } from './address'

export const createCustomerValidation = yup.object({
    name: yup.string().required('A name is required.'),
    address: addressValidation,
    contacts: yup.array().of(createContactValidation)
})

export const updateCustomerValidation = createCustomerValidation
