import * as React from "react"
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom"


// Auth
import { Login } from './pages/Auth/Login'
import { ConfirmPhone } from './pages/Auth/Login/ConfirmPhone'

// In-App
import { AppLayout } from './layouts/App'
import { Dashboard } from './pages/App/Dashboard'

// Print View
import { PrintLayout } from './layouts/Print'

// customers
import { Customers } from './pages/App/Customers'
import { AddCustomer } from './pages/App/Customers/Add'
import { EditCustomer } from './pages/App/Customers/Edit'

// users
import { Users } from './pages/App/Users'
import { Technicians } from './pages/App/Users/Technicians'

// work order
import { WorkOrders } from './pages/App/WorkOrders'
import { AddWorkOrder } from './pages/App/WorkOrders/Add'
import { EditWorkOrder } from './pages/App/WorkOrders/Edit'
import { PrintWorkOrder } from './pages/App/WorkOrders/Print'

// service contracts
import { ServiceContracts } from './pages/App/ServiceContracts'
import { AddServiceContract } from './pages/App/ServiceContracts/Add'
import { EditServiceContract } from './pages/App/ServiceContracts/Edit'
import { PrintServiceContract } from './pages/App/ServiceContracts/Print'

// Projects
import { Projects } from './pages/App/Projects'
import { AddProject } from './pages/App/Projects/Add'
import { EditProject } from './pages/App/Projects/Edit'
import { PrintProject } from './pages/App/Projects/Print'

// MessageCenter
import { MessageCenter } from './pages/App/MessageCenter'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/phone-confirm",
    element: <ConfirmPhone />,
  },
  {
    path: "/app",
    element: <AppLayout />,
    children: [
        {
            path: "dashboard",
            element: <Dashboard />
        },

        // customers
        {
            path: "customers",
            element: <Customers />
        },
        {
            path: "customers/new",
            element: <AddCustomer />
        },
        {
            path: "customers/:customerId",
            element: <EditCustomer />
        },

        // users
        {
            path: "users",
            element: <Users />
        },
        {
            path: "technicians",
            element: <Technicians />
        },

        // work orders
        {
            path: "work-orders",
            element: <WorkOrders />
        },
        {
            path: "work-orders/new",
            element: <AddWorkOrder />
        },
        {
            path: "work-orders/:workOrderId",
            element: <EditWorkOrder />
        },

        // service contracts
        {
            path: "service-contracts",
            element: <ServiceContracts />
        },
        {
            path: "service-contracts/new",
            element: <AddServiceContract />
        },
        {
            path: "service-contracts/:serviceContractId",
            element: <EditServiceContract />
        },

        // projects
        {
            path: "projects",
            element: <Projects />
        },
        {
            path: "projects/new",
            element: <AddProject />
        },
        {
            path: "projects/:projectId",
            element: <EditProject />
        },

        // message center
        {
            path: "messages",
            element: <MessageCenter />
        },
    ]
  },
  {
    path: "/print",
    element: <PrintLayout />,
    children: [
        {
            path: "work-orders/:workOrderId",
            element: <PrintWorkOrder />
        },
        {
            path: "service-contracts/:serviceContractId",
            element: <PrintServiceContract />
        },
        {
            path: "projects/:projectId",
            element: <PrintProject />
        },
    ]

  }
])

export const Routes = () => {

    return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    )
}
