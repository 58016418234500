import React from 'react'
import { PrintTable } from './index'
import { ContactType } from '../../../server/models/Contact'

type Props = {
    contacts: ContactType[]
}

export const Contacts: React.FC<Props> = ({ contacts }) => {
    return (
            <PrintTable>
                <thead>
                    <tr>
                        <th colSpan={2}>Contacts</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        contacts.map((contact, index) => (
                            <>
                                <tr>
                                    <td>Title</td>
                                    <td>{contact.title}</td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>{contact.name}</td>
                                </tr>
                                {contact.email?.length > 0 && <tr>
                                    <td>Email Address</td>
                                    <td>{contact.email}</td>
                                </tr>}
                                {
                                    contact.phoneNumbers.map(({ phone, name }) => (
                                        <tr>
                                            <td>{name}</td>
                                            <td>{phone}</td>
                                        </tr>
                                    ))
                                }
                                { contacts[index + 1] && <tr>
                                    <td colSpan={2} />
                                </tr>}
                            </>
                        ))
                    }
                </tbody>
            </PrintTable>
    )
}

